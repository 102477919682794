@import "../abstracts/helpers/functions";
.container-advertising {
  .monitor-container {
    .imgWrapper {
      position: absolute;
      width: 501px;
      height: 348px;
      bottom: 36px;

      &:hover {
        box-shadow: 0 0 20px rgba(100, 100, 100, .9);
        -ms-transform: scale(1.01);
        // /* IE 9 */
        -webkit-transform: scale(1.01);
        // /* Safari 3-8 */
        transform: scale(1.01);
      }

      > img {
        width: 100%;
        height: 100%;
      }
    }

    &.demo-monitor {

      .map-content {
        padding: rem(61px) rem(30px) rem(35px);

        .map-row {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;

          &--1 {
            padding: 0 rem(45px);
          }

          &--2 {
            padding: rem(39px) 0 rem(47px);
          }

          &--3 {
            padding: 0 rem(45px);
          }
        }

        .map-col {
          background: #00930f;
          border: 1px dashed #727272;
          transition: background-color 0.3s;

          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          p {
            margin: 0;
            font-size: em(12px);
            font-weight: 700;
            color: #ffffff;
            width: 100%;
            word-break: break-all;
            padding: 10px;
            text-align: center;
            transition: color 0.3s;
          }

          &:not(.map-col--reserved):not(.map-col--disabled):hover,
          &--selected {
            background-color: #000000;
            border-color: transparent;

            p {
              color: #ffffff;
            }
          }

          &--disabled {
            opacity: 0.7;
            cursor: default;
          }

          &--reserved {
            background-color: #f13825;
            border-color: transparent;

            cursor: default;

            p {
              color: #ffffff;
            }
          }

          &--small {
            width: rem(100px) !important;
            height: rem(60px) !important;
          }

          &--big {
            width: rem(60px) !important;
            height: rem(120px) !important;
          }

          &--type-1 {
            width: rem(100px);
            height: rem(60px);
          }

          &--type-2 {
            width: rem(60px);
            height: rem(120px);

            p {
              transform: rotate(270deg);
              transform-origin: center;
              white-space: nowrap;
              width: rem(120px);
            }
          }

          &--type-3 {
            width: rem(100px);
            height: rem(100px);
          }
        }
      }
    }

    &.medium-monitor {
      width: rem(1500px);
      background-color: transparent;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 100%;

      .monitor-content {
        padding: 0 0 rem(35px);
        display: flex;

        .monitor-zone {
          display: block;
          padding: rem(28px) rem(10px) 0 rem(30px);
          width: rem(500px);

          &-1 {
            display: flex;
            // flex-direction: column;
            justify-content: space-between;

            .map-row {
              &--1 {
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .map-col {
                  &--small {
                    width: rem(100px) !important;
                    height: rem(60px) !important;
                  }

                  &--big {
                    width: rem(120px) !important;
                    height: rem(60px) !important;
                  }
                }
              }

              &--2 {
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .map-col {
                  margin-top: 16px;

                  &:first-child {
                    margin-top: 0px;
                  }

                  &--small {
                    width: rem(60px) !important;
                    height: rem(90px) !important;
                  }

                  &--big {
                    width: rem(120px) !important;
                    height: rem(60px) !important;
                  }
                }
              }
            }
          }

          &-2 {
            display: flex;
            // flex-direction: column;
            justify-content: space-between;

            .map-row {
              &--1 {
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .map-col {
                  &--small {
                    width: rem(100px) !important;
                    height: rem(60px) !important;
                  }

                  &--big {
                    width: rem(120px) !important;
                    height: rem(60px) !important;
                  }
                }
              }

              &--2 {
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .map-col {
                  margin-top: 16px;

                  &:first-child {
                    margin-top: 0px;
                  }

                  &--small {
                    width: rem(60px) !important;
                    height: rem(90px) !important;
                  }

                  &--big {
                    width: rem(120px) !important;
                    height: rem(60px) !important;
                  }
                }
              }
            }
          }

          &-3 {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .map-row {
              &--1 {
                display: flex;
                justify-content: center;

                .map-col {
                  &:first-child {
                    margin-right: 15px;
                  }

                  &--small {
                    width: rem(100px) !important;
                    height: rem(60px) !important;
                  }

                  &--big {
                    width: rem(120px) !important;
                    height: rem(60px) !important;
                  }
                }
              }

              &--2 {
                display: flex;
                justify-content: space-between;

                .map-col {
                  margin-top: 16px;

                  &:first-child {
                    margin-top: 0px;
                  }

                  &--small {
                    width: rem(60px) !important;
                    height: rem(90px) !important;
                  }

                  &--big {
                    width: rem(120px) !important;
                    height: rem(60px) !important;
                  }
                }
              }

              &--3 {
                .map-col {
                  margin-top: 16px;

                  &:first-child,
                  &:last-child {
                    margin-top: 0px;
                  }

                  &--small {
                    width: rem(100px) !important;
                    height: rem(60px) !important;
                  }

                  &--reverseSmall {
                    width: rem(60px) !important;
                    height: rem(100px) !important;
                  }

                  &--reverseBig {
                    width: rem(60px) !important;
                    height: rem(120px) !important;
                  }

                  &--big {
                    width: rem(120px) !important;
                    height: rem(60px) !important;
                  }
                }

                .flCol {
                  display: flex;
                  flex-direction: column;
                  margin-right: 16px;

                  &.fl {
                    margin-left: 16px;
                  }

                  .sm {
                    margin-top: 0px !important;
                    height: rem(50px) !important;
                    width: rem(68px) !important;
                  }

                  .bg {
                    margin-top: 0px !important;
                    height: rem(50px) !important;
                    width: rem(124px) !important;
                  }
                }
              }
            }
          }

          .contSpace {
            display: flex;
            justify-content: space-between;

            > .map-row--2 {
              > .map-col--small {
                width: 2.05rem !important;
                height: 8.625rem !important;
              }
            }
          }
        }

        .map-row {
          display: flex;
          justify-content: center;
          margin-bottom: rem(14px);
        }

        .map-col {
          background: #00930f;
          border: 1px dashed #727272;
          transition: background-color 0.3s;

          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          p {
            margin: 0;
            font-size: em(10px);
            font-weight: 700;
            color: #ffffff;
            width: 100%;
            word-break: break-all;
            padding: 10px;
            text-align: center;
            transition: color 0.3s;
          }

          &:not(.map-col--reserved):not(.map-col--disabled):hover,
          &--selected {
            background-color: red !important;
            border-color: transparent;

            p {
              color: #ffffff;
            }
          }

          &--disabled {
            opacity: 0.7;
            cursor: default;
          }

          &--reserved {
            background-color: #f13825 !important;
            border-color: transparent;

            cursor: default;

            p {
              color: #ffffff;
            }
          }

          &-small {
            width: rem(80px);
            height: rem(40px);
          }

          &-medium {
            width: rem(80px);
            height: rem(50px);
          }

          &-big {
            width: rem(80px);
            height: rem(80px);
          }
        }
      }
    }

    &.big-monitor {
      background-color: transparent;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 100%;

      .monitor-content {
        padding: 0 0 rem(35px);
        display: flex;

        .contSpace {
          display: flex;
          justify-content: space-between;
        }

        .monitor-zone {
          display: block;
          padding: rem(28px) rem(10px) 0 rem(30px);
          width: 100%;

          &-1 {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .map-row {
              &--1 {
                display: flex;
                justify-content: center;

                .map-col {
                  &:first-child {
                    margin-right: 15px;
                  }

                  &--small {
                    width: rem(84px) !important;
                    height: rem(30px) !important;
                  }

                  &--big {
                    width: rem(120px) !important;
                    height: rem(60px) !important;
                  }
                }
              }

              &--2 {
                display: flex;
                justify-content: space-between;

                .map-col {

                  // margin-top: 16px;
                  &:first-child {
                    margin-top: 0px;
                  }

                  &--small {
                    width: 2.1rem !important;
                    height: rem(84px) !important;
                  }

                  &--big {
                    width: rem(120px) !important;
                    height: rem(60px) !important;
                  }
                }
              }

              &--3 {

                .map-col {
                  margin-top: 16px;

                  &:first-child,
                  &:last-child {
                    margin-top: 0px;
                  }

                  &--small {
                    width: rem(100px) !important;
                    height: rem(60px) !important;
                  }

                  &--reverseSmall {
                    width: 2.1rem !important;
                    height: rem(84px) !important;
                  }

                  &--reverseBig {
                    width: rem(60px) !important;
                    height: rem(120px) !important;
                  }

                  &--big {
                    width: rem(120px) !important;
                    height: rem(60px) !important;
                  }
                }

                .flCol {
                  display: flex;
                  flex-direction: column;
                  margin-right: 16px;

                  &.fl {
                    margin-left: 16px;
                  }

                  .sm {
                    margin-top: 0px !important;
                    height: rem(42px) !important;
                    width: rem(30px) !important;
                  }

                  .bg {
                    margin-top: 0px !important;
                    height: rem(42px) !important;
                    width: rem(124px) !important;
                  }
                }
              }
            }
          }

          &-2 {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .map-row {
              &--1 {
                display: flex;
                justify-content: center;

                .map-col {
                  &:first-child {
                    margin-right: 15px;
                  }

                  &--small {
                    width: rem(84px) !important;
                    height: rem(30px) !important;
                  }

                  &--big {
                    width: rem(120px) !important;
                    height: rem(60px) !important;
                  }
                }
              }

              &--2 {
                display: flex;
                justify-content: space-between;
                // margin-top: 30px;

                .map-col {

                  // margin-top: 16px;
                  &:first-child {
                    margin-top: 0px;
                  }

                  &--small {
                    width: rem(100px) !important;
                    height: rem(60px) !important;
                  }

                  &--reverseSmall {
                    width: 2.1rem !important;
                    height: rem(84px) !important;
                  }

                  &--reverseBig {
                    width: rem(60px) !important;
                    height: rem(104px) !important;
                  }

                  &--big {
                    width: rem(120px) !important;
                    height: rem(60px) !important;
                  }
                }

                .flCol {
                  display: flex;
                  flex-direction: column;
                  margin-right: 16px;
                  margin-top: rem(2px);

                  &:first-child {
                    margin-top: 0px;
                  }

                  &.fl {
                    margin-left: 16px;
                  }

                  > .flCol {
                    &:first-child {
                      margin-right: 0px;
                    }

                    &:last-child {
                      margin-left: rem(30px);
                    }
                  }

                  .sm {
                    margin-top: 0px !important;
                    height: rem(20px) !important;
                    width: rem(84px) !important;
                  }

                  .bg {
                    margin-top: 0px !important;
                    height: rem(20px) !important;
                    width: rem(124px) !important;
                  }
                }
              }

              &--3 {
                margin-top: 30px;

                .map-col {
                  margin-top: 16px;

                  &:first-child,
                  &:last-child {
                    margin-top: 0px;
                  }

                  &--small {
                    width: rem(100px) !important;
                    height: rem(60px) !important;
                  }

                  &--reverseSmall {
                    width: 2.1rem !important;
                    height: rem(84px) !important;
                  }

                  &--reverseBig {
                    width: rem(60px) !important;
                    height: rem(104px) !important;
                  }

                  &--big {
                    width: rem(120px) !important;
                    height: rem(60px) !important;
                  }
                }

                .flCol {
                  display: flex;
                  flex-direction: column;
                  // margin-right: 16px;

                  &.fl {
                    margin-left: 16px;
                  }

                  .sm {
                    margin-top: 0px !important;
                    height: rem(26px) !important;
                    width: rem(84px) !important;
                    margin-left: rem(170px);
                  }

                  .bg {
                    margin-top: 0px !important;
                    height: rem(42px) !important;
                    width: rem(124px) !important;
                  }
                }
              }

              &--4 {
                display: flex;
                justify-content: center !important;

                .map-col {
                  margin-top: 16px;

                  &:first-child,
                  &:last-child {
                    margin-top: 0px;
                  }

                  &--small {
                    width: rem(100px) !important;
                    height: rem(60px) !important;
                  }

                  &--reverseSmall {
                    width: rem(30px) !important;
                    height: rem(84px) !important;
                  }

                  &--reverseBig {
                    width: rem(60px) !important;
                    height: rem(104px) !important;
                  }

                  &--big {
                    width: rem(120px) !important;
                    height: rem(60px) !important;
                  }
                }

                .flCol {
                  display: flex;
                  // flex-direction: column;
                  align-items: center;
                  justify-content: center;

                  .sm {
                    margin-top: 0px !important;
                    height: rem(84px) !important;
                    width: rem(24px) !important;
                    // margin-left: rem(196px);
                  }

                  .bg {
                    margin-top: 0px !important;
                    height: rem(42px) !important;
                    width: rem(124px) !important;
                  }
                }
              }

              &--5 {
                display: flex;
                justify-content: space-between;
                margin-top: 30px;

                .map-col {

                  // margin-top: 16px;
                  &:first-child {
                    margin-top: 0px;
                  }

                  &--small {
                    width: rem(100px) !important;
                    height: rem(60px) !important;
                  }

                  &--reverseSmall {
                    width: 2.1rem !important;
                    height: rem(84px) !important;
                  }

                  &--reverseBig {
                    width: rem(60px) !important;
                    height: rem(104px) !important;
                  }

                  &--big {
                    width: rem(120px) !important;
                    height: rem(60px) !important;
                  }
                }

                .flCol {
                  display: flex;
                  flex-direction: column;
                  margin-right: 16px;
                  margin-top: rem(2px);

                  &:first-child {
                    margin-top: 0px;
                  }

                  &.fl {
                    margin-left: 16px;
                  }

                  > .flCol {
                    &:first-child {
                      margin-left: rem(120px);
                    }

                    &:last-child {
                      margin-right: rem(0);
                    }
                  }

                  .sm {
                    margin-top: 0px !important;
                    height: rem(20px) !important;
                    width: rem(84px) !important;
                  }

                  .bg {
                    margin-top: 0px !important;
                    height: rem(20px) !important;
                    width: rem(124px) !important;
                  }
                }
              }
            }
          }

          &-3 {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .map-row {
              &--1 {
                display: flex;
                justify-content: space-between;

                .map-col {
                  &--small {
                    width: rem(100px) !important;
                    height: rem(60px) !important;
                  }

                  &--big {
                    width: rem(120px) !important;
                    height: rem(60px) !important;
                  }
                }
              }

              &--2 {
                display: flex;
                align-items: center;
                justify-content: space-between;
                // flex-direction: column;

                .map-col {
                  // margin-top: 16px;

                  &:first-child {
                    margin-top: 0px;
                  }

                  &--small {
                    width: 2.1rem !important;
                    height: 5.25rem !important;
                  }

                  &--big {
                    width: rem(120px) !important;
                    height: rem(60px) !important;
                  }
                }
              }
            }
          }

          &-4 {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            // justify-content: space-between;

            .map-row {
              &--1 {
                display: flex;
                justify-content: center;

                .map-col {
                  &:first-child {
                    margin-right: 15px;
                  }

                  &--small {
                    width: rem(84px) !important;
                    height: rem(30px) !important;
                  }

                  &--big {
                    width: rem(120px) !important;
                    height: rem(60px) !important;
                  }
                }
              }

              &--2 {
                display: flex;
                justify-content: space-between;

                .map-col {

                  // margin-top: 16px;
                  &:first-child {
                    margin-top: 0px;
                  }

                  &--small {
                    width: rem(100px) !important;
                    height: rem(60px) !important;
                  }

                  &--reverseSmall {
                    width: 2.1rem !important;
                    height: rem(60px) !important;
                  }

                  &--reverseBig {
                    width: rem(60px) !important;
                    height: rem(104px) !important;
                  }

                  &--big {
                    width: rem(120px) !important;
                    height: rem(60px) !important;
                  }
                }

                .flCol {
                  display: flex;
                  flex-direction: column;
                  margin-right: 16px;
                  margin-top: rem(2px);

                  &:first-child {
                    margin-top: 0px;
                  }

                  &.fl {
                    margin-left: 16px;
                  }

                  > .flCol {
                    &:first-child {
                      margin-right: 0px;
                    }

                    &:last-child {
                      margin-left: rem(30px);
                    }
                  }

                  .sm {
                    margin-top: 0px !important;
                    height: rem(20px) !important;
                    width: rem(84px) !important;
                  }

                  .bg {
                    margin-top: 0px !important;
                    height: rem(20px) !important;
                    width: rem(124px) !important;
                  }
                }
              }

              &--3 {
                .map-col {
                  margin-top: 16px;

                  &:first-child,
                  &:last-child {
                    margin-top: 0px;
                  }

                  &--small {
                    width: rem(100px) !important;
                    height: rem(60px) !important;
                  }

                  &--reverseSmall {
                    width: 2.1rem !important;
                    height: rem(60px) !important;
                  }

                  &--reverseBig {
                    width: rem(60px) !important;
                    height: rem(104px) !important;
                  }

                  &--big {
                    width: rem(120px) !important;
                    height: rem(60px) !important;
                  }
                }

                .flCols {
                  display: flex;
                  flex-direction: column;
                  transform: rotate(24deg);

                  &.fl {
                    margin-left: 16px;
                  }

                  .sm {
                    margin-top: 0px !important;
                    height: rem(26px) !important;
                    width: rem(84px) !important;
                    // margin-left: rem(170px);
                  }

                  .bg {
                    margin-top: 0px !important;
                    height: rem(30px) !important;
                    width: rem(124px) !important;
                  }
                }

                .flColb {
                  display: flex;
                  flex-direction: column;
                  justify-content: flex-end;
                  transform: rotate(163deg);

                  &.fl {
                    margin-left: 16px;
                  }

                  .sm {
                    margin-top: 0px !important;
                    height: rem(26px) !important;
                    width: rem(84px) !important;
                    margin-left: rem(170px);
                  }

                  .bg {
                    margin-top: 0px !important;
                    height: rem(30px) !important;
                    width: rem(124px) !important;
                  }
                }
              }

              &--4 {
                display: flex;
                justify-content: center !important;

                .map-col {
                  margin-top: 16px;

                  &:first-child,
                  &:last-child {
                    margin-top: 0px;
                  }

                  &--small {
                    width: rem(100px) !important;
                    height: rem(60px) !important;
                  }

                  &--reverseSmall {
                    width: rem(30px) !important;
                    height: rem(60px) !important;
                  }

                  &--reverseBig {
                    width: rem(60px) !important;
                    height: rem(104px) !important;
                  }

                  &--big {
                    width: rem(120px) !important;
                    height: rem(60px) !important;
                  }
                }

                .flCol {
                  display: flex;
                  // flex-direction: column;
                  align-items: center;
                  margin-right: 100px;
                  justify-content: center;
                  transform: rotate(30deg);

                  .sm {
                    margin-top: 0px !important;
                    height: rem(84px) !important;
                    width: rem(24px) !important;
                    // margin-left: rem(196px);
                  }

                  .bg {
                    margin-top: 0px !important;
                    height: rem(42px) !important;
                    width: rem(124px) !important;
                  }
                }
              }

              &--5 {
                display: flex;
                justify-content: space-between;

                .map-col {

                  // margin-top: 16px;
                  &:first-child {
                    margin-top: 0px;
                  }

                  &--small {
                    width: rem(100px) !important;
                    height: rem(60px) !important;
                  }

                  &--reverseSmall {
                    width: 2.1rem !important;
                    height: rem(60px) !important;
                  }

                  &--reverseBig {
                    width: rem(60px) !important;
                    height: rem(104px) !important;
                  }

                  &--big {
                    width: rem(120px) !important;
                    height: rem(60px) !important;
                  }
                }

                .flColb {
                  display: flex;
                  flex-direction: column;
                  // margin-right: 128px;
                  justify-content: flex-end;
                  // margin-top: rem(2px);
                  transform: rotate(190deg);

                  &:first-child {
                    margin-top: 0px;
                  }

                  &.fl {
                    margin-left: 16px;
                  }

                  > .flCol {
                    &:first-child {
                      margin-left: rem(120px);
                    }

                    &:last-child {
                      margin-right: rem(0);
                    }
                  }

                  .sm {
                    margin-top: 0px !important;
                    height: rem(20px) !important;
                    width: rem(84px) !important;
                  }

                  .bg {
                    margin-top: 0px !important;
                    height: rem(20px) !important;
                    width: rem(124px) !important;
                  }
                }

                .flCol {
                  display: flex;
                  flex-direction: column;

                  &:first-child {
                    margin-top: 0px;
                  }

                  &.fl {
                    margin-left: 16px;
                  }

                  > .flCol {
                    &:first-child {
                      margin-left: rem(120px);
                    }

                    &:last-child {
                      margin-right: rem(0);
                    }
                  }

                  .sm {
                    margin-top: 0px !important;
                    height: rem(20px) !important;
                    width: rem(84px) !important;
                  }

                  .bg {
                    margin-top: 0px !important;
                    height: rem(20px) !important;
                    width: rem(124px) !important;
                  }
                }
              }

              &--6 {
                display: flex;
                justify-content: space-between;

                .map-col {

                  // margin-top: 16px;
                  &:first-child {
                    margin-top: 0px;
                  }

                  &--small {
                    width: rem(100px) !important;
                    height: rem(60px) !important;
                  }

                  &--reverseSmall {
                    width: 2.1rem !important;
                    height: rem(60px) !important;
                  }

                  &--reverseBig {
                    width: rem(60px) !important;
                    height: rem(104px) !important;
                  }

                  &--big {
                    width: rem(120px) !important;
                    height: rem(60px) !important;
                  }
                }

                .flCol {
                  display: flex;
                  flex-direction: column;

                  &:first-child {
                    margin-top: 0px;
                  }

                  &.fl {
                    margin-left: 16px;
                  }

                  > .flCol {
                    &:first-child {
                      margin-left: rem(120px);
                    }

                    &:last-child {
                      margin-right: rem(0);
                    }
                  }

                  .sm {
                    margin-top: 0px !important;
                    height: rem(20px) !important;
                    width: rem(84px) !important;
                  }

                  .bg {
                    margin-top: 0px !important;
                    height: rem(20px) !important;
                    width: rem(124px) !important;
                  }
                }
              }
            }
          }
        }

        .map-row {
          display: flex;
          justify-content: center;
          margin-bottom: rem(14px);
          // align-items: flex-end;
        }

        .map-col {
          //background: #00930f;
          //border: 1px dashed #727272;
          transition: background-color 0.3s;

          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          p {
            margin: 0;
            font-size: em(10px);
            font-weight: 700;
            color: #ffffff;
            width: 100%;
            word-break: break-all;
            padding: 10px;
            text-align: center;
            transition: color 0.3s;
          }

          &:not(.map-col--reserved):not(.map-col--disabled):hover,
          &--selected {
            background-color: red !important;
            border-color: transparent;

            p {
              color: #ffffff;
            }
          }

          &--disabled {
            opacity: 0.7;
            cursor: default;
          }

          &--reserved {
            background-color: #f13825 !important;
            border-color: transparent;

            cursor: default;

            p {
              color: #ffffff;
            }
          }

          &-small {
            width: rem(80px);
            height: rem(40px);
          }

          &-medium {
            width: rem(80px);
            height: rem(50px);
          }

          &-big {
            width: rem(80px);
            height: rem(80px);
          }
        }
      }
    }
  }
}
.spBetween {
  justify-content: space-between !important;
}
.alert {
  text-align: center;
  margin: 5px auto;
}
