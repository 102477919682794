.map-container {
  width: 100%;
  max-width: 1564px;
  margin: 0 auto;
  background: inherit !important;
}
.bordeGrey {
  width: 100%;
  max-width: 98vw;
  margin-left: auto;
  margin-right: auto;
  margin-top: 22px;
  border: 1px solid #d1d1d1;
  overflow-x: auto;
  overflow-y: hidden;
  .empty-f {
    border: none;
    overflow: inherit;
    > .footerBig {
      display: none;
    }
  }
}
.extra-big-nav {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(18%, 1fr));
  grid-column-gap: 20px;
}
.nav-item {
  height: 86px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  line-height: 18px;
  color: #ffffff;
  cursor: pointer;
  &.extra-big-nav-0 {
    background-color: #f97878;
  }
  &.extra-big-nav-1 {
    background-color: #858df6;
  }
  &.extra-big-nav-2 {
    background-color: #a0d282;
  }
  &.extra-big-nav-3 {
    background-color: #efa15c;
  }
}
.trg-v {
  margin: 0 5px;
  width: 60px;
  height: 30px;
  border-left: solid 30px transparent;
  border-right: solid 30px transparent;
  padding: 0;
}
.triangleRed {
  border-top: solid 30px #f97878;
}
.triangleBlue {
  border-top: solid 30px #858df6;
}
.triangleGreen {
  border-top: solid 30px #a0d282;
}
.triangleOrange {
  border-top: solid 30px #efa15c;
}
.hall-title {
  width: 100%;
  text-align: center;
  color: #000000;
  font-weight: bold;
  padding: 35px 10px;
  font-size: 18px;
  line-height: 22px;
}
.footerBig {
  width: 100%;
  text-align: center;
  > img {
    display: block;
    width: 1562px;
    height: auto;
    //&:hover {
    //  box-shadow: 10px 10px 10px 10px #888888;
    //}
  }
}
.map-col {
  transition: background-color 240ms;
}
.red-color {
  background-color: #f97878;
}
.v-4 {
  .red-color {
    background-color: inherit;
  }
}
.blue-color {
  background-color: #858df6;
}
.green-color {
  background-color: #a0d282;
}
.yellow-color {
  background-color: #efa15c;
}
.big-map {
  width: 100%;
  height: 100%;
  background-color: transparent;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100%;
}

.map-content {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(18%, 1fr));
  width: 100%;
  min-width: 1562px;
  padding: 0 20px;
  .map-zone {
    padding: 28px 20px 0 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // background: red;

    // >div {
    //   border-right: 1px solid black;
    // }

    &-1 {
      border-right: 1px solid black;
    }

    &-2 {
      border-right: 1px solid black;
    }
    &-3 {
      border-right: 1px solid black;
    }

    &:last-child {
      border-right: none;
    }

    .map-myCol {
      display: flex;
      justify-content: space-between;

      &-Big {
        margin-top: 2rem;
      }
      &-Medium {
        > div {
          margin-top: 3.5rem;

          &:first-child {
            margin-top: 0;
          }
        }
      }

      > div {
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        > div {
          margin-top: 54px;

          &:first-child {
            margin-top: 0;
          }
        }
      }
    }

    .map-row {
      &-1 {
        padding-right: 20px;
        padding-left: 20px;
      }
      &-2 {
      }
      &-3 {
      }
      &-4 {
        padding-top: 15px;
        padding-right: 20px;
        padding-left: 20px;
        .map-col-small {
          width: 4rem;
          height: 2.5rem;
        }
      }
    }
  }

  .map-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  .map-col {
    //background: #00930f;
    //border: 1px dashed #727272;
    //transition: background-color 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    p {
      margin: 0;
      font-size: 10px;
      font-weight: 700;
      color: #ffffff;
      width: 100%;
      word-break: break-all;
      padding: 10px;
      text-align: center;
      transition: color 0.3s;
    }

    &:not(.map-col--reserved):not(.map-col--disabled):hover,
    &--selected {
      background-color: red !important;
      border-color: transparent;
      p {
        color: #ffffff;
      }
    }
    &--disabled {
      opacity: 0.75;
      cursor: default;
    }
    &--reserved {
      background-color: #f13825 !important;
      border-color: transparent;
      cursor: default;
      p {
        color: #ffffff;
      }
    }
    &-small {
      width: 3rem;
      height: 4rem;
      margin-left: 0.9rem;
      margin-right: 0.9rem;
    }

    &-medium {
      width: 3.8rem;
      height: 4.125rem;
    }

    &-big {
      width: 4.8rem;
      height: 4.8rem;
    }
    &--reverseSmall {
      width: 3rem;
      height: 4rem;
      margin-left: 0.9rem;
      margin-right: 0.9rem;
    }
  }

  //.monitor-zone {
  //  display: block;
  //  padding: rem(28px) rem(10px) 0 rem(30px);
  //  width: rem(500px);
  //
  //  &-1 {
  //    display: flex;
  //    // flex-direction: column;
  //    justify-content: space-between;
  //
  //    .map-row {
  //      &--1 {
  //        display: flex;
  //        flex-direction: column;
  //        justify-content: space-between;
  //
  //        .map-col {
  //          &--small {
  //            width: rem(100px) !important;
  //            height: rem(60px) !important;
  //          }
  //
  //          &--big {
  //            width: rem(120px) !important;
  //            height: rem(60px) !important;
  //          }
  //        }
  //      }
  //
  //      &--2 {
  //        display: flex;
  //        flex-direction: column;
  //        justify-content: space-between;
  //
  //        .map-col {
  //          margin-top: 16px;
  //
  //          &:first-child {
  //            margin-top: 0px;
  //          }
  //
  //          &--small {
  //            width: rem(60px) !important;
  //            height: rem(90px) !important;
  //          }
  //
  //          &--big {
  //            width: rem(120px) !important;
  //            height: rem(60px) !important;
  //          }
  //        }
  //      }
  //    }
  //  }
  //
  //  &-2 {
  //    display: flex;
  //    // flex-direction: column;
  //    justify-content: space-between;
  //
  //    .map-row {
  //      &--1 {
  //        display: flex;
  //        flex-direction: column;
  //        justify-content: space-between;
  //
  //
  //        .map-col {
  //          &--small {
  //            width: rem(100px) !important;
  //            height: rem(60px) !important;
  //          }
  //
  //          &--big {
  //            width: rem(120px) !important;
  //            height: rem(60px) !important;
  //          }
  //        }
  //      }
  //
  //      &--2 {
  //        display: flex;
  //        flex-direction: column;
  //        justify-content: space-between;
  //
  //        .map-col {
  //          margin-top: 16px;
  //
  //          &:first-child {
  //            margin-top: 0px;
  //          }
  //
  //          &--small {
  //            width: rem(60px) !important;
  //            height: rem(90px) !important;
  //          }
  //
  //          &--big {
  //            width: rem(120px) !important;
  //            height: rem(60px) !important;
  //          }
  //        }
  //      }
  //    }
  //  }
  //
  //  &-3 {
  //    display: flex;
  //    flex-direction: column;
  //    justify-content: space-between;
  //
  //    .map-row {
  //      &--1 {
  //        display: flex;
  //        justify-content: center;
  //
  //        .map-col {
  //          &:first-child {
  //            margin-right: 15px;
  //          }
  //
  //          &--small {
  //            width: rem(100px) !important;
  //            height: rem(60px) !important;
  //          }
  //
  //          &--big {
  //            width: rem(120px) !important;
  //            height: rem(60px) !important;
  //          }
  //        }
  //      }
  //
  //      &--2 {
  //        display: flex;
  //        justify-content: space-between;
  //
  //        .map-col {
  //          margin-top: 16px;
  //
  //          &:first-child {
  //            margin-top: 0px;
  //          }
  //
  //          &--small {
  //            width: rem(60px) !important;
  //            height: rem(90px) !important;
  //          }
  //
  //          &--big {
  //            width: rem(120px) !important;
  //            height: rem(60px) !important;
  //          }
  //        }
  //      }
  //
  //      &--3 {
  //        .map-col {
  //          margin-top: 16px;
  //
  //          &:first-child,
  //          &:last-child {
  //            margin-top: 0px;
  //          }
  //
  //          &--small {
  //            width: rem(100px) !important;
  //            height: rem(60px) !important;
  //          }
  //
  //          &--reverseSmall {
  //            width: rem(60px) !important;
  //            height: rem(100px) !important;
  //          }
  //
  //          &--reverseBig {
  //            width: rem(60px) !important;
  //            height: rem(120px) !important;
  //          }
  //
  //          &--big {
  //            width: rem(120px) !important;
  //            height: rem(60px) !important;
  //          }
  //        }
  //
  //        .flCol {
  //          display: flex;
  //          flex-direction: column;
  //          margin-right: 16px;
  //
  //          &.fl {
  //            margin-left: 16px;
  //          }
  //
  //          .sm {
  //            margin-top: 0px !important;
  //            height: rem(50px) !important;
  //            width: rem(68px) !important;
  //          }
  //
  //          .bg {
  //            margin-top: 0px !important;
  //            height: rem(50px) !important;
  //            width: rem(124px) !important;
  //          }
  //        }
  //      }
  //    }
  //  }
  //
  //  .contSpace {
  //    display: flex;
  //    justify-content: space-between;
  //
  //    >.map-row--2 {
  //      >.map-col--small {
  //        width: 2.05rem !important;
  //        height: 8.625rem !important;
  //      }
  //    }
  //  }
  //}
}
// created by Hayk Yeghiazaryan

.close-icon-b {
  flex: 0 0 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
}
.bottom-container {
  width: 100%;
  padding-top: 30px;
}
.bottom-container-inner-item {
  width: 100%;
  max-width: 500px;
  padding: 0 16px;
  margin: 0 auto 30px auto;
  &:last-child {
    margin-bottom: 0;
  }
}
.bottom-container-inner-item-txt-block {
  width: 100%;
}
.bottom-container-inner-item-txt {
  font-size: 12px;
  line-height: 14px;
  font-weight: bold;
  color: #000000;
  text-align: center;
}
.file-browse-wr {
  width: 100%;
  background: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 16px;
  margin-top: 16px;
}
.inner-file-browse-wr {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.lbl-btn {
  display: inline-block;
  vertical-align: top;
  max-width: 100%;
  height: 30px;
  border: 1px solid rgba(#000000, 0.5);
  padding: 0 4px;
  font-size: 16px;
  line-height: 30px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  input {
    display: none;
  }
}
.close-wr {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  overflow: hidden;
}
.close-btn {
  flex: 0 0 auto;
  width: 16px;
  height: 16px;
  border: none;
  cursor: pointer;
  margin: 0 8px;
  background: url("../../images/close.png") no-repeat 50% 50% / contain;
}
.close-txt {
  flex: 1;
  font-size: 14px;
  line-height: 16px;
  color: #a2a2a2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.inputs-section-wr {
  width: 100%;
}
.inputs-section-wr-title {
  width: 100%;
  padding: 30px 16px;
}
.inputs-section-wr-title-txt {
  width: 100%;
  text-align: center;
  color: rgba(53, 53, 53, 0.7);
  font-size: 34px;
  line-height: 36px;
  font-weight: 600;
  text-transform: uppercase;
}
.inputs-section-wr-middle {
  width: 100%;
  max-width: 1560px;
  padding: 0 16px;
  margin: 0 auto;
}
.inputs-section-wr-middle-top {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
  max-width: 1036px;
  margin: 0 auto;
  overflow: hidden;
}
.inputs-section-wr-middle-top-item {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 4px;
  overflow: hidden;
}
.dflexLeft {
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: #a2a2a2;
  margin: 10px 0;
}
.inputs-section-wr-middle-bottom {
  width: 100%;
  max-width: 750px;
  margin: 0 auto;
  padding-top: 30px;
}
.inputs-section-wr-middle-bottom-txtarea {
  width: 100%;
  height: 200px;
  padding: 10px 20px;
  font-size: 16px;
  line-height: 18px;
  font-weight: bold;
  border-radius: 5px;
  margin: 16px 0;
  box-shadow: 10px 10px 5px grey, 1px 1px 7px grey;
  border: none;
  resize: none;
  outline: none;
  overflow: hidden;
}
.inputs-section-wr-bottom {
  width: 100%;
  padding: 16px;
}
.inner-inputs-section-wr-bottom {
  width: 100%;
}
.inner-inputs-section-wr-bottom-btn-wr {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 750px;
  margin: 0 auto;
  overflow: hidden;
}
.inner-inputs-section-wr-bottom-btn-wr-btn {
  height: 26px;
  font-size: 16px;
  font-weight: bold;
  color: #000000;
  margin: 0 10px;
  border: none;
  border-bottom: 5px solid #000000;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  outline: none;
  cursor: pointer;
}
.alert-message {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 500px;
  min-height: 50px;
  margin: 16px auto;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
  background-color: #dc3545;
  padding: 16px;
  border-radius: 8px;
}
.app-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: rgba(#000000, 0.4);
  padding: 16px;
}
.modal-content {
  width: 100%;
  max-width: 1100px;
}
.croppie-container {
  background: #f4f4f4;
  color: #474747;
  position: relative;

  &_close {
    position: absolute;
    right: 22px;
    top: 22px;
    cursor: pointer;
  }

  &-title {
    padding: 127px 0 18px;
    font: normal normal normal 16px/22px $font-main-montserrat;
    color: #545F7E;
    text-align: center;
  }

  .cr-boundary, .container--engine {
    width: 100% !important;
    max-width: 940px;
    margin: 0 auto;
    padding: 0;

    .cr-vp-square {
      border: 0 !important;
    }
  }

  .cr-slider-wrap {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    max-width: 940px;
    margin: 0 auto;
    z-index: 100;

    input[type=range] {
      -webkit-appearance: none;
      width: 100%;
      transform: translate(0, 13px);
    }

    input[type=range]:focus {
      outline: none;
    }

    input[type=range]::-webkit-slider-runnable-track {
      width: 100%;
      height: 4px;
      cursor: pointer;
      animate: 0.2s;
      background: #999999;
    }

    input[type=range]::-webkit-slider-thumb {
      height: 20px;
      width: 20px;
      background: #fff;
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 1);
      cursor: pointer;
      -webkit-appearance: none;
      margin-top: -7px;
    }
  }
}

.croppie-btns-row {
  padding: 44px 0;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.croppie-btn {
  border-radius: 25px 0 0 25px;
  background: #363F5A;
  width: 190px;
  height: 50px;
  border: 0;
  font: normal normal 16px/25px $font-main-montserrat;
  color: #FFFFFF;
  cursor: pointer;
}
.choose-location-design-content {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
  max-width: 1300px;
  height: 720px;
  background: url("../../images/plan-bg.png") no-repeat 50% 50% / cover;
  margin: auto;
  z-index: 1;
  padding: 40px;
  overflow: hidden;
}
.choose-location-design-content-top {
  flex: 0 0 auto;
  overflow: hidden;
}
.inner-choose-location-design-content-top {
  width: 100%;
  max-width: 50%;
  margin: 16px auto;
}
.inner-choose-location-design-content-top-title {
  text-align: center;
  font-size: 26px;
  color: rgba(53, 53, 53, 0.7);
  line-height: 30px;
  margin-bottom: 16px;
  font-weight: 700;
  text-transform: uppercase;
}
.inner-choose-location-design-content-top-txt {
  text-align: center;
  font-size: 16px;
  color: #a2a2a2;
  line-height: 18px;
  margin-bottom: 16px;
}
.choose-location-design-content-middle {
  flex: 1;
  overflow: hidden;
  position: relative;
}
.inner-choose-location-design-content-middle {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  white-space: nowrap;
  position: absolute;
  top: 0;
  left: 0;
}
.inner-choose-location-design-content-middle-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}
.inner-choose-location-design-content-middle-row-item {
  margin-right: 16px;
  &:last-child {
    margin-right: 0;
  }
}
.i-iframe {
  display: block;
}
.inner-choose-location-design-content-middle-row-txt {
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
  color: #00930f;
  margin-bottom: 4px;
}
.inner-choose-location-design-content-middle-row-lbl-row {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10px 0;
}
.inner-choose-location-design-content-middle-row-lbl {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  box-shadow: 0 0 0 1px #000000 inset;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
}
.inner-choose-location-design-content-middle-row-input {
  display: none;
  &:checked + .for-inner-choose-location-design-content-middle-row-lbl {
    opacity: 1;
  }
}
.for-inner-choose-location-design-content-middle-row-lbl {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #000000;
  opacity: 0;
  transition: opacity 240ms;
}
.choose-location-design-content-bottom {
  flex: 0 0 auto;
  overflow: hidden;
}
.choose-location-design-content-bottom-btns-wr {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 10px 0;
  padding: 10px 0;
  overflow: hidden;
}
.choose-location-design-content-bottom-btn {
  height: 30px;
  font-size: 18px;
  font-weight: bold;
  color: #000000;
  margin: 0 10px;
  border: none;
  border-bottom: 5px solid #000000;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-transform: uppercase;
  outline: none;
  cursor: pointer;
}
.inner-choose-location-design-content-middle-spinner-wr {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
