@import '../abstracts/helpers/functions';


.grid-container {
  display: grid;
  grid-template-columns: auto auto auto;

  &-item {
    text-align: center;
    padding: 20px;
    cursor: pointer;
  }

  .isGridItemActive {
    background-color: yellow;
  }
}

.app-modal {
  //height: auto;
  //border-radius: 15px;
  //overflow: hidden;
  //z-index: 999999;
  //top: 50% !important;
  //transform: translateY(-50%) !important;
  //// left: 50%;
  //right: unset;
  //bottom: unset;
  //width: auto;
  //max-width: 70%;

  .confCont {
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 30px;

    >.cust {
      display: flex;
      margin-top: 20px;

      >button {
        margin-right: 10px !important;

        &:disabled {
          color: #e5e5e5;
          border-color: #e5e5e5;
          background: transparent;
          outline: none !important;
        }
      }
    }
  }

  //&.croppie-modal {
  //  max-width: 90%;
  //}

  &.design-modal {
    //max-width: 90%;

    .modal-content {
      background: transparent;
    }
  }

  .btn-close {
    z-index: 999;
    top: 0;
    right: rem(-25px);
    background: black;
    border-radius: 0;
    padding: 5px;
    min-height: 0;
  }

  .modal-content {
    box-shadow: none;
    border-radius: 0;
    border: none;
  }

  .app-modal__content {
    border-radius: rem(10px) 0 rem(10px) rem(10px);
    overflow: hidden;
  }

  .displayflex {
    display: flex;
    justify-content: center;

    >button {
      margin: 0px !important;
      margin-left: 16px !important;

      &:last-child {}
    }
  }

  .container-stand {
    // width: 100%;
    // min-height: 100%;
    // display: flex;
    // flex-direction: column;

    // border-radius: rem(10px);

    // margin: 0;

    .pad-left {
      padding-left: 16px !important;
    }

    .cust-textArea {
      width: 100%;
      resize: none;
    }

    .cust-inp {
      padding-top: 16px;
      padding-bottom: 16px;

      input,
      textarea {
        color: black;
        border: none;
        outline: none;
      }
    }

    .orange-color {
      color: orange !important;
    }

    .black-color {
      color: black;
    }

    .b-bottom {
      border-bottom: 5px solid black;
    }

    .b-bottom-active {
      border-bottom: 5px solid orange;
    }

    .cont {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &-p {
        display: flex;
        justify-content: center;
        padding: 4px 32px;

        >p {
          text-align: center;
          width: 80%;
          color: black;
        }
      }

      &-lab {
        margin-top: 16px !important;
        width: 100%;

        >div {
          display: flex;
          justify-content: center !important;

          >label {
            margin: 0 !important;
            color: black;
          }
        }
      }
    }

    .content {
      display: flex;
      flex: 1 0 auto;
      width: 100%;

      background: #11141d;
      background-size: cover;
      background-repeat: no-repeat;
      padding: 0;

      .content__row {
        display: flex;
        width: 100%;
        padding-left: em(45px);
      }

      .content__image {
        img {
          display: block;
          right: 0;
          top: 0;
        }
      }

      .content__col {
        max-width: rem(360px);
        padding: em(150px) 0;
        z-index: 5;
      }

      h1 {
        font-size: em(100px);
        letter-spacing: 5px;
      }
    }

    .footer {
      background: #11141d;
    }
  }

  .form-col {
    max-width: rem(500px);
    padding: 0;
  }

  h4 {
    color: #3d3d3d;
    font-weight: 500;
    font-size: em(16px);
  }

  .file-upload-row {
    margin-top: rem(30px) !important;
  }

  .card {
    border: 0;
    background: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
  }

  .card.file-browse {
    max-width: rem(500px);
    height: rem(70px);
    margin: 0 auto;
    margin-top: 10px;

    .card-body {
      padding: rem(20px) rem(30px);
      display: flex;
      justify-content: center;

      >label {
        margin: 0px !important;
        display: flex;
        justify-content: center;
        color: black !important;
      }

      p {
        line-height: rem(15px);
        max-height: rem(30px);
        overflow: hidden;
        margin-right: rem(80px) !important;
      }
    }

    .btn-link {
      width: auto;
      margin: 0;
      padding: 0;

      width: rem(100px);
      margin-right: rem(-130px);
      margin-left: rem(30px);
    }

    .file-browsed {
      margin: 0 auto;
      width: rem(300px);
    }
  }

  .custom-label {
    >label {
      display: flex;
      align-items: flex-start;

      p {
        font-size: 30px;
        margin-bottom: 10px;
        color: red;
        height: 16px;
      }
    }
  }

  // @media (min-width: 576px) {
  //   &:not(.croppie-modal):not(.design-modal) {
  //     max-width: rem(730px);
  //   }
  // }
}
