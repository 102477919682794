@import '../abstracts/helpers/functions';

.choose-location-design-container {
  width: rem(1300px);
  height: rem(800px);
  background-color: transparent;
  background-image: url('../../images/plan-bg.png');
  background-size: 100% 100%;
  background-position: center center;
  background-repeat: no-repeat;
  margin: auto;
  z-index: 1;

  ::-webkit-scrollbar {
  height: 10px;
}

/* Track */
// ::-webkit-scrollbar-track {
//   box-shadow: inset 0 0 5px grey;
//   border-radius: 10px;
// }

/* Handle */
// ::-webkit-scrollbar-thumb {
//   background: red;
//   border-radius: 10px;
// }

/* Handle on hover */
// ::-webkit-scrollbar-thumb:hover {
//   background: #b30000;
// }

  .choose-location-design-content {
    padding: rem(80px) rem(60px) rem(80px);
  }

  &--img-bg {
    background: #565656;
  }

  &--design-list {
    max-height: rem(650px);
    overflow-y: auto;
    white-space: nowrap;
    padding: 0;

    .card{
      width: rem(390px);
      margin-right: rem(20px);

      &:last-child{
        margin-right: 0;
      }
    }
  }

  .custom-radio {
    padding: 0;
    text-align: center;
  }

  .custom-control-label::before,
  .custom-control-label::after {
    left: 0;
    cursor: pointer;
  }

  .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23000'/%3e%3c/svg%3e");
  }
  .custom-control-input:checked ~ .custom-control-label::before {
    border-color: #000000;
    background-color: #f4f4f4;
  }
  .custom-control-label::before {
    border-color: #000000;
    background-color: #f4f4f4;
  }

  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    border-color: #000000;
    background-color: #f4f4f4;
  }

  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: none;
  }

  .card {
    box-shadow: none;

    .card-text {
      color: #00930f;
    }
  }
}
