@import '../../abstracts/helpers/functions';

.App-header {
  .nav-wrapper {
    padding: 0 0.75rem;

    .brand-logo {
      .logo {
        width: rem(60px);
      }
    }
  }

  nav ul a {
    float: left;
  }
}
