//@import "../../assets/styles/helpers/functions";
.container-stand{
  width: 100%;
  padding: 0 1.5% 2.5%;
}
.mini-stn-header-b{
  width: 100%;
  text-align: center;
  padding: 40px 15px 30px;
}
.st-b-title{
  font-size: 35px;
  text-transform: uppercase;
  font-weight: bold;
  color: rgba(53,53,53,.7);
  margin-bottom: 10px;
}
.st-b-title-desc-n{
  color: #ffa500;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.2;
}


//New styles END-----------------------------------

.buttons-wr-inner {
  display: flex;
  justify-content: center;
  padding-top: 16px;
  > button {
    margin-left: 16px;
  }
}

.custInpBig {
  background-color: #F4F4F4;
  color: black;
  outline: none;
  padding: 10px 20px;
  border: none;
  margin-top: 8px;
}

.bRadFirst {
  border-radius: 5px 0 0 5px;
}

.bRadLast {
  border-radius: 0 5px 5px 0;
}

.SearchBar {
  display: flex;
  justify-content: center;
  margin-bottom: 60px;

  .SearchBarInp {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    width: 100%;
    max-width: 1564px;
    position: relative;

    > div {
      display: flex;
      border: 1px solid #D1D1D1 !important;
      background-color: transparent;
      border-radius: 10px;
      padding: 9px 11px;
      width: 370px;

      >input {
        outline: none;
        flex: 1;
        width: 360px;
        border: none;

        &::placeholder {
          color: black;
        }
      }

      .SearchImgDiv {
        flex: 0 0 20px;
        display: flex;
        align-items: center;

        >img {
          width: 11px;
        }
      }
    }


    .SearchBarCont {
      background-color: #FFFFFF;
      display: flex;
      flex-direction: column;
      position: absolute;
      z-index: 1;
      top: calc(100% + 8px);
      padding-top: 20px;
      padding-bottom: 20px;
      justify-content: center;
      box-shadow: 1px 1px 7px grey;
      margin-top: 0;
      border-radius: 15px;
      width: 100%;
      padding-left: 33px;
      max-width: 370px;

      &-Wrapper {
        &_exh {
          color: black;
          font-weight: bold;
          font-size: 17px;
        }
        &-Cont {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 4px;
          border-bottom: 1px solid #f1f1f1;

          >div {
            display: flex;
            border-radius: 5px;
            justify-content: space-between;
            align-items: center;
            padding: 8px;
            width: 118px;

            >p {
              margin: 0;
              margin-left: 8px;
              color: white;
              font-size: 12px;
            }

            >h5 {
              color: white;
              margin: 0;
              font-size: 12px;
              font-weight: bold;
            }
          }

          >p {
            margin: 0;
            font-size: 12px;
            color: black;
          }
        }
      }

      .row {
        height: 100%;

        .col {
          height: 100%;


          img {
            height: 100%;
          }
        }
      }
    }

    @media screen and (max-width:1024px) {
      width: 100%;

      >div {
        width: 100%;
      }
    }
  }
}

// .customFooter {
//   height: 376px;
//   background-color: transparent;
//   background-image: url(/static/media/footer.ae424480.png);
//   background-position: center center;
//   background-repeat: no-repeat;
//   background-size: 79%;
// }

.container-stand {
  // width: 100%;
  // min-height: 100%;
  // display: flex;
  // flex-direction: column;

  // border-radius: rem(10px);

  // margin: 0;

  .pad-left {
    padding-left: 16px !important;
  }

  //.red-color {
  //  background-color: #f97878 !important;
  //}
  //
  //.blue-color {
  //  background-color: #858df6 !important;
  //}
  //
  //.green-color {
  //  background-color: #a0d282 !important;
  //}
  //
  //.yellow-color {
  //  background-color: #efa15c !important;
  //}

  .textareaDiv {
    max-width: 750px;
    margin-top: 16px;
    width: 100%;
    min-width: 314px;
  }

  .cust-textArea {
    width: 100%;
    max-width: 750px !important;
    outline: none;
    padding: 10px 20px;
    border-radius: 5px;
    box-shadow: 1px 1px 7px grey;
    margin-top: 16px;
    border: none;
    resize: none;
    height: 200px !important;
    min-height: 200px !important;
    max-height: 200px !important;
  }

  .cust-inp {
    padding-top: 16px;
    padding-bottom: 16px;

    input,
    textarea {
      color: black;
      border: none;
      outline: none;
    }
  }

  .orange-color {
    color: orange ;
  }

  .black-color {
    color: black;
  }

  .b-bottom {
    border-bottom: 5px solid black;
  }

  .b-bottom-active {
    border-bottom: 5px solid orange;
  }

  .cont {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &-p {
      display: flex;
      justify-content: center;
      padding: 4px 32px;

      >p {
        text-align: center;
        width: 80%;
        color: black;
      }
    }

    &-lab {
      margin-top: 16px !important;
      width: 100%;

      >div {
        display: flex;
        justify-content: center !important;

        >label {
          margin: 0 !important;
          color: black;
        }
      }
    }
  }

  .content {
    display: flex;
    flex: 1 0 auto;
    width: 100%;

    background: #11141d;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 0;

    .content__row {
      display: flex;
      width: 100%;
      padding-left: em(45px);
    }

    .content__image {
      img {
        display: block;
        right: 0;
        top: 0;
      }
    }

    .content__col {
      max-width: rem(360px);
      padding: em(150px) 0;
      z-index: 5;
    }

    h1 {
      font-size: em(100px);
      letter-spacing: 5px;
    }
  }

  .footer {
    background: #11141d;
  }
}

.form-col {
  max-width: rem(500px);
  padding: 0;
}

h4 {
  color: #3d3d3d;
  font-weight: 500;
  font-size: em(16px);
}

.file-upload-row {
  margin-top: rem(30px);
}

.card {
  border: 0;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.card.file-browse {
  max-width: rem(500px);
  height: rem(70px);
  margin: 0 auto;

  .card-body {
    padding: rem(20px) rem(30px);
    p {
      line-height: rem(15px);
      max-height: rem(30px);
      overflow: hidden;
      // margin-right: rem(80px) !important;
    }
  }

  .btn-link {
    width: auto;
    margin: 0;
    padding: 0;

    width: rem(100px);
    margin-right: rem(-130px);
    margin-left: rem(30px);
  }

  .file-browsed {
    margin: 0 auto;
    width: rem(300px);
  }
}

.redP {
  color: red;
  margin: 0;
}

.dflexLeft {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.custom-label {
  >label {
    display: flex;
    align-items: flex-start;

    p {
      font-size: 30px;
      margin-bottom: 10px;
      color: red;
      height: 16px;
    }
  }
}
