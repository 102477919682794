html {
  font-family: ProximaNova, Arial, Helvetica, sans-serif;
  font-size: 16px;
  scroll-behavior: smooth;
  width: 100%;
  height: 100%;
  touch-action: pan-x pan-y;
}
body{
  width: 100%;
  height: 100%;
}
@media (hover: hover) and(min-width: 1024px) {
  ::-webkit-scrollbar {
    width: 2px;
    border-radius: 2px;
    //padding-right: 10px;
  }
  ::-webkit-scrollbar-track {
    background: rgba(#000000, 0.05);
    border-radius: 2px;
  }
  ::-webkit-scrollbar-thumb {
    background: #c8c7c7;
    border-radius: 2px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555555;
  }
}

.app-root{
  width: 100%;
  height: 100%;
}
.mTop57 {
  margin-top: 57px;
}

html[dir='rtl'],
html[dir='rtl'] * {
  direction: rtl !important;
}

.App {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: auto 10fr auto;
  grid-template-areas: "header" "content" "footer";
}
.App-header{
  width: 100%;
  grid-area: header;
}
.App-content {
  width: 100%;
  grid-area: content;
}
.Appfooter{
  width: 100%;
  grid-area: footer;
}
.continue-checkout-stand{
width: 100%;
  height: 100%;
}
