.not-found {

  &__main {
    margin-top: 92px;
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
      font: normal normal bold 88px $font-main-montserrat;
      color: #363F5A;
      margin: 0 0 15px;
      padding: 0;
    }

    img {
      width: 553px;
    }

    p {
      font: normal normal normal 28px/38px $font-main-montserrat;
      color: #545F7E;
      margin: 42px 0 24px;
      padding: 0;
      max-width: 728px;
      text-align: center;
    }

    a {
      border-radius: 26px;
      background: #363F5A;
      font: normal normal 600 16px $font-main-montserrat;
      color: #FFFFFF;
      border: 0;
      padding: 16px 32px;
      cursor: pointer;

      &:hover {
        background: #545f7e;
      }
    }
  }
}
