.floor-plan {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: auto 11fr;
  grid-gap: 22px;
  grid-template-areas: "top" "bottom";
  .floor-plan-top {
    grid-area: top;
    min-width: 0;
  }
  .floor-plan-section {
    grid-area: bottom;
    min-width: 0;
  }
  &__header {
    padding: 32px 58px 0 58px;
  }

  &__title {
    text-align: center;
    margin-top: 48px;
    margin-bottom: 58px;
    h2 {
      font: normal normal bold 40px/40px $font-main-montserrat;
      color: #363F5A;
    }

    p {
      font: normal normal normal 16px/22px $font-main-montserrat;
      color: #545F7E;
      font-size: 16px;
      line-height: 22px;
      margin-top: 16px;
    }
  }

  &__main {
    margin: 0 auto;
    width: 100%;
    height: 100%;
    padding: 0 32px 12px 32px;
    max-width: 1028px;

    &_search {
      width: 100%;
      background: rgba(229, 229, 229, 0.2);
      border-radius: 8px;
      height: 40px;
      display: flex;
      align-items: center;
      position: relative;
      margin-bottom: 24px;
      > img {
        margin: 0 8px 0 16px;
      }

      .close-icon {
        margin: 0 8px 0 16px;
        display: none;

        &.show {
          display: block;
        }
      }

      input {
        flex-grow: 1;
        border: 0;
        padding: 0;
        background: transparent;
        font-family: $font-main-montserrat;
        font-weight: 500;
        color: #363F5A;
        font-size: 14px;
        &::placeholder {
          color: #cccccc;
          font-weight: 400;
        }
      }

      .close-icon {
        cursor: pointer;
      }

      .search__result {
        position: absolute;
        left: 0;
        top: 41px;
        width: 100%;
        background: #f9f9fb;
        padding: 22px 24px 22px 48px;
        border-radius: 8px;
        z-index: 50;

        &_title {
          font: normal normal 700 14px/18px $font-main-montserrat;
          color: #363F5A;
          margin-bottom: 16px;
        }

        &_items {
          display: flex;
          flex-direction: column;
          gap: 10px;
        }

        &_item {
          display: grid;
          grid-template-columns: auto 1fr auto;
          border-bottom: 1px solid #E5E5E5;
          height: 24px;

          &_name {
            padding-left: 12px;
            font: normal normal 400 12px/15px $font-main-montserrat;
            color: #363F5A;
            align-self: center;
          }

          &_info {
            display: flex;
            background: #F97878;
            border-radius: 6px;
            padding: 4px 8px;
            color: #FFFFFF;
            align-self: start;

            h5 {
              margin-right: 32px;
              font: normal normal 700 10px/13px $font-main-montserrat;
            }

            p {
              font: normal normal 400 10px/13px $font-main-montserrat;
            }
          }
        }
      }
    }
    &_halls {
      position: relative;
      overflow: hidden;
      .halls__header {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(14%, 1fr));
        grid-row-gap: 16px;
        grid-column-gap: 8px;
        margin-bottom: 32px;
        &.hide {
          display: none;
        }

        &_hall {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 40px;
          border-radius: 10px;
          font: normal normal 500 16px/19px $font-main-montserrat;
          color: #FFFFFF;
          cursor: pointer;
          opacity: .6;
          transition: .2s;
          position: relative;
        }

        .selected {
          font: normal normal bold 16px/19px $font-main-montserrat;
          opacity: 1;
        }

        .hall_back_0 {
          background: #ff0000;
        }

        .hall_back_1 {
          background: #2196f3;
        }

        .hall_back_2 {
          background: #a0d282;
        }

        .hall_back_3 {
          background: #efa15c;
        }

        .hall_border_0 {
          border-top: 13px solid #ff0000;
        }

        .hall_border_1 {
          border-top: 13px solid #2196f3;
        }

        .hall_border_2 {
          border-top: 13px solid #a0d282;
        }

        .hall_border_3 {
          border-top: 13px solid #efa15c;
        }

        .hall_arrow {
          width: 26px;
          height: 13px;
          border-left: 13px solid transparent;
          border-right: 13px solid transparent;
          padding: 0;
          position: absolute;
          bottom: -13px;
          left: 50%;
          transform: translate(-50%, 0);
          display: none;

          &.show {
            display: block;
          }
        }
      }

      .halls__zones {
        width: 100%;
        padding: 0 20px;
        position: relative;

        .carousel {
          .slider {
            width: 100%;
          }

          .control-arrow {
            position: absolute;
            top: 50%;
            padding: 0;
            transform: translate(-50%, 0);
            background-image: url("../../images/floor_arrow.png");
            width: 66px;
            height: 66px;
            opacity: .4;

            &::before {
              content: none;
            }

            &:focus {
              opacity: .4;
            }

            &:hover {
              background-color: transparent;
              opacity: 1;
            }
          }

          .control-prev {
            left: 137px;
            right: auto;
          }

          .control-next {
            right: 137px;
            left: auto;
            transform: rotate(180deg) translate(-50%, 0);
          }
        }
        .map-zone {

          &.map-zone_0 {
            .map-zone_main {
              .map-col {
                border-color: #ff0000;
                background: rgba(255, 0, 0, 0.7);

                &--disabled {
                  background: rgba(255, 0, 0, 0.4);
                }

                &--reserved {
                  background: #ff0000;
                }

                &:not(.map-col--disabled):not(.map-col--reserved):hover {
                  background: #ff0000 !important;
                }
              }
            }
          }

          &.map-zone_1 {
            .map-zone_main {
              .map-col {
                border-color: #2196f3;
                background: rgba(33, 150, 243, 0.7);

                &--disabled {
                  background: rgba(33, 150, 243, 0.4);
                }

                &--reserved {
                  background: #2196f3;
                }

                &:not(.map-col--disabled):not(.map-col--reserved):hover {
                  background: #2196f3 !important;
                }
              }
            }
          }

          &.map-zone_2 {
            .map-zone_main {
              .map-col {
                border-color: #a0d282;
                background: rgba(160, 210, 130, 0.7);

                &--disabled {
                  background: rgba(160, 210, 130, 0.4);
                }

                &--reserved {
                  background: #a0d282;
                }

                &:not(.map-col--disabled):not(.map-col--reserved):hover {
                  background: #a0d282 !important;
                }
              }
            }
          }

          &.map-zone_3 {
            .map-zone_main {
              .map-col {
                border-color: #efa15c;
                background: rgba(239, 161, 92, 0.7);

                &--disabled {
                  background: rgba(239, 161, 92, 0.4);
                }

                &--reserved {
                  background: #efa15c;
                }

                &:not(.map-col--disabled):not(.map-col--reserved):hover {
                  background: #efa15c !important;
                }
              }
            }
          }

          .map-zone__title {
            font: normal normal 500 16px/25px $font-main-montserrat;
            color: #545F7E;
          }

          .map-zone_main {
            position: relative;
            width: 100%;
            max-width: 428px;
            display: flex;
            justify-content: space-between;
            padding: 34px;
            box-sizing: content-box;
            margin: 0 auto;

            .map-zone_center {
              display: flex;
              justify-content: space-between;
              flex-direction: column-reverse;
              width: 290px;
              > div {
                display: flex;
                justify-content: space-around;
                margin-top: 40px;
                &:last-child {
                  margin-top: 0;
                }
              }
            }

            .map-zone_start {
              display: flex;
              flex-direction: column-reverse;
              justify-content: space-around;

              &-1 {
                padding-right: 20px;
                padding-left: 20px;
              }

              &-4 {
                padding-top: 15px;
                padding-right: 20px;
                padding-left: 20px;

                .map-col-small {
                  width: 4rem;
                  height: 2.5rem;
                }
              }
            }

            .map-zone_end {
              .map-col {
                &-small {
                  width: 30px;
                  height: 51px;
                }
              }
            }

            .map-col {
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              border: 1px solid;
              position: relative;

              p {
                font: normal normal 400 12px/15px $font-main-montserrat;
                color: #FFFFFF;
                transition: color 0.3s;
              }

              &:not(.map-col--reserved):not(.map-col--disabled):hover,
              &--selected {
                background-color: red !important;
                border-color: transparent;

                p {
                  color: #ffffff;
                }
              }

              &--disabled {
                cursor: default;
              }

              &--reserved {
                background-color: #f13825 !important;
                border-color: transparent;
                cursor: default;

                p {
                  color: #ffffff;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  white-space: nowrap;
                  padding: 0 6px;
                  font: normal normal 500 12px/15px $font-main-montserrat;
                }
              }

              &--tooltip {
                padding: 3px 8px 5px;
                background: #FFFFFF padding-box;
                box-shadow: 0 3px 6px #0000004D !important;
                border-radius: 2px;
                font: normal normal normal 12px/16px $font-main-montserrat;
                color: #000000;
                height: 24px;
                margin-top: -15px;
                margin-left: -10px;
                white-space: nowrap;
              }

              &-small {
                width: 51px;
                height: 30px;
              }

              &-medium {
                width: 56px;
                height: 53px;
              }

              &-big {
                width: 77px;
                height: 67px;
              }

              &--reverseSmall {
                width: 51px;
                height: 30px;
              }
            }
          }
        }


        .carousel-indicator {
          background: #E5E5E5;
          padding: 6px 26px;
          font: normal normal 500 14px/18px $font-main-montserrat;
          color: #545F7E;
          border-radius: 5px;
          width: 80px;
          margin: 0 auto;
          text-align: center;
        }

      }

    }
  }

  &__submit {
    opacity: 0;
    pointer-events: none;
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: grid;
    grid-template-columns: 1fr 1fr;
    transition: .2s linear;

    .submit-form {
      height: 100vh;
      background: white;
      padding: 114px 0 0 74px;
      transform: translate(100%, 0);
      transition: .2s linear;
      position: relative;

      &__booths {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr;
        height: 100%;

        .booths__header {
          text-align: center;
          padding-right: 74px;
          margin-bottom: 42px;

          h2 {
            font: normal normal 700 40px/40px $font-main-montserrat;
            color: #363F5A;
            margin-bottom: 14px;
          }

          p {
            font: normal normal 400 16px/22px $font-main-montserrat;
            color: #545F7E;
          }
        }

        .booths__main {
          position: relative;
          padding-right: 38px;
          margin-right: 36px;
          overflow-y: auto;
          overflow-x: hidden;
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 16px;

          .booth {
            cursor: pointer;

            &__preview-stand {
              border: 6px solid #4B50AC;
              aspect-ratio: 26/23;
            }

            &__preview-img {
              width: 100%;
              padding-top: 88%;
              background-size: cover;
              background-position: center center;

            }


            &__info {
              margin-top: 16px;

              h4 {
                margin-bottom: 13px;
                font: normal normal 600 14px/25px $font-main-montserrat;
                color: #545F7E;
              }

              p {
                color: #545F7E;
                font-size: 12px;
                line-height: 16px;
                font-weight: normal;
              }

              &.to-correct {
                margin-top: 10px;
              }
            }
          }

          &_spinner {
            display: grid;
            grid-column-start: 1;
            grid-column-end: 4;
          }
        }
      }

      &__form {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr;
        position: relative;
        height: 100%;

        .form__header {
          text-align: center;
          padding-right: 74px;
          margin-bottom: 42px;

          h2 {
            font: normal normal 700 40px/40px $font-main-montserrat;
            color: #363F5A;
            margin-bottom: 14px;
          }

          p {
            font: normal normal 400 16px/22px $font-main-montserrat;
            color: #545F7E;
          }
        }

        .form__fields {
          padding-right: 38px;
          margin-right: 36px;
          overflow-y: auto;
          overflow-x: hidden;
          scroll-behavior: smooth;

          .fields__files {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 16px;
            margin-bottom: 36px;
            .file-section {
              width: 100%;
              aspect-ratio: 25/23;
              background: rgba(229, 229, 229, 0.4);
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              margin-bottom: 16px;
              cursor: pointer;
              padding: 16px;
              position: relative;

              img {
                margin-bottom: 16px;
                transition: .2s;
              }

              p {
                font: normal normal 400 12px/15px $font-main-montserrat;
                color: #545F7E;
                text-align: center;
                word-wrap: anywhere;
                height: 15px;

                &.redP {
                  color: #f97878;
                }
              }

              &:hover {
                img {
                  transform: scale(1.1);
                }
              }
            }

            h4 {
              margin-bottom: 13px;
              font: normal normal 600 14px/25px $font-main-montserrat;
              color: #545F7E;
            }

            p {
              color: #545F7E;
              font: normal normal normal 12px/16px Montserrat;
            }

            input {
              display: none;
            }
          }

          .fields__company-info {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 16px 12px;

            &_field {
              div {
                font: normal normal 700 14px/25px $font-main-montserrat;
                color: #545F7E;
                margin-bottom: 10px;
              }

              input, textarea {
                background: rgba(229, 229, 229, 0.4);
                border-radius: 8px;
                padding: 14px 16px;
                color: #363F5A;
                font: normal normal 400 12px/15px $font-main-montserrat;
                width: 100%;
                border: 0;
                height: 44px;
                box-sizing: border-box;

                &::placeholder {
                  color: #CCCCCC;
                }
              }

              &.textarea {
                grid-column-start: 1;
                grid-column-end: 4;

                textarea {
                  height: 156px;
                }
              }

              &.error {
                div {
                  color: #f97878;
                }
              }
            }
          }
        }
      }

      .custLoad {
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(255, 255, 255, 0.5);
      }
    }

    .submit-close-button {
      position: absolute;
      right: 36px;
      top: 36px;
      cursor: pointer;
    }

    .prev-button {
      position: absolute;
      top: 36px;
      left: 36px;
      cursor: pointer;
    }

    .next-button {
      text-align: right;
      margin: 54px 0;

      button {
        border-radius: 25px 0 0 25px;
        background: #363F5A;
        opacity: .6;
        width: 190px;
        height: 50px;
        border: 0;
        font: normal normal 700 16px/25px $font-main-montserrat;
        color: #FFFFFF;
      }

      &.active {
        button {
          opacity: 1;
          cursor: pointer;
        }
      }
    }

    &.show {
      opacity: 1;
      pointer-events: auto;

      .submit-form {
        transform: translate(0, 0);
      }
    }
  }
}

.advertising-plan__main_halls {
  .halls__zones {
    .map-zone {
      .map-zone_main {
        flex-direction: column;
        align-items: center;

        img {
          max-width: 352px;
          max-height: 250px;
        }

        &__monitors {
          display: none;
          justify-content: space-between;
          width: 352px;
          padding-bottom: 27px;
          div {
            background-image: url("../../images/monitor.svg");
            background-repeat: no-repeat;
            opacity: .8;
            width: 87px;
            height: 29px;
            cursor: pointer;
            &:hover {
              opacity: 1;
            }

            &.reserved {
              opacity: .2;
              cursor: auto;
            }
          }

          &.rotated {
            transform: rotate(180deg);
          }
        }

        &.revers {
          display: flex;
          flex-direction: row;
          justify-content: center;
          gap: 16px;
          margin-top: 69px;
          .map-zone_main__monitors {
            width: 29px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 52px;
            padding: 0;
            div {
              background-image: url("../../images/monitor-rotated.svg");
              width: 29px;
              height: 87px;
              margin: 0;
            }

          }
        }
      }
    }
  }
}
@media only screen and (max-width: 320px) {
  .floor-plan {
    .floor-plan__main {
      .advertising-plan__main_halls .halls__zones .map-zone {
        .map-zone_main {
          max-width: 208px;
          img {
            max-width: 208px;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 979px) {
  .prev-button {
    top: 0;
    left: 0;
  }
  .floor-plan {
    .floor-plan__header {
      text-align: center;
      padding: 14px 0;
      img {
        max-width: 126px;
      }
    }
    .floor-plan__title {
      margin: 0;
      h2 {
        font-size: 18px;
        line-height: 22px;
      }
      p {
        font-size: 12px;
        line-height: 16px;
        margin-top: 8px;
      }
    }
    .floor-plan__main {
      padding: 0 16px ;
      margin: 0;
      min-width: 0;
      .floor-plan__main_search {
        margin-top: 16px;
        height: 36px;
      }
      .halls__header_hall {
        height: 32px;
      }
      .floor-plan__main_halls {
        margin-top: 12px;
        .halls__header {
          grid-template-columns: repeat(auto-fit, minmax(20%, 1fr));
          margin-bottom: 24px;
          grid-column-gap: 2px;
          .hall_back_0,
          .hall_back_1,
          .hall_back_2,
          .hall_back_3{
            font-size: 12px;
            line-height: 16px;
            text-align: center;
            padding: 4px 0;
          }
          .hall_arrow {
            width: 8px;
            height: 8px;
            bottom: -8px;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
          }
          .hall_border_0,
          .hall_border_1,
          .hall_border_2,
          .hall_border_3 {
            border-top-width: 8px;
          }
        }
        .halls__zones {
          padding: 0 0 10px 0;
          .carousel-indicator {
            font-size: 12px;
            line-height: 30px;
            width: 60px;
            height: 30px;
            padding: 0;
          }
          .carousel {
            .control-arrow {
              width: 32px;
              height: 32px;
              background-size: cover;
              background-repeat: no-repeat;
            }
            .control-next {
              top: 26px;
              right: 16px;
            }
            .control-prev {
              top: 26px;
              left: 16px;
            }
          }
          .map-zone {
            .map-zone_main {
              max-width: 318px;
              justify-content: center;
              .map-col-medium {
                width: 34px;
                height: 36px;
              }
              .map-col-big {
                width: 52px;
                height: 44px;
              }
              .map-col-small {
                width: 34px;
                height: 20px;
              }
              .map-zone_end {
                .map-col-small {
                  width: 20px;
                  height: 36px;
                  margin: 0;
                }
              }
            }
            .map-zone__title {
              font-size: 14px;
              line-height: 18px;
              margin-bottom: 60px;
            }
          }
        }
      }
    }
    .advertising-plan__main_halls .halls__zones .map-zone {
      .map-zone__title {
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 60px;
      }
      .map-zone_main__monitors {
        div {
          height: 24px;
        }
      }
      .map-zone_main {
        max-width: 262px;
        img {
          max-width: 262px;
        }
        &.revers {
          margin-top: 0;
          .map-zone_main__monitors {
            width: 24px;
          }
        }
        .map-zone_end {
          .map-col-small {
            margin: 0;
          }
        }
      }
    }
    .floor-plan__submit {
      display: block;
      background: unset;
      .submit-close-button {
        position: absolute;
        right: 16px;
        top: 14px;
        width: 24px;
        height: 24px;
        z-index: 1;
        img {
          max-width: 100%;
        }
      }
      .prev-button {
        top: 14px;
        left: 14px;
        width: 24px;
        height: 24px;
        z-index: 1;
        img {
          max-width: 100%;
        }
      }
      .submit-form {
        height: 100%;
        padding: 0;
        .form__fields {
          padding: 0;
          margin: 0;
          width: 100%;
          height: 100%;
          .fields__files_file {
            width: 100%;
            .file-section {
              aspect-ratio: unset;
              min-height: 155px;
              margin-bottom: 12px;
              padding: 0;
              img {
                max-width: 56px;
                max-height: 56px;
                margin-bottom: 8px;
              }
              p {
                font-size: 12px;
                color: rgba(#545F7E, 0.4);
              }
            }
          }
          .fields__company-info_field  {
            width: 100%;
          }
        }
        .submit-form__booths,
        .submit-form__form {
          grid-template-rows: auto 10fr auto;
          grid-gap: 12px;
          padding: 75px 0 36px 0;
          .booths__header,
          .form__header{
            padding: 0 16px;
            margin: 0;
            h2 {
              font-size: 18px;
              line-height: 22px;
              margin-bottom: 8px;
            }
            p {
              font-size: 12px;
              line-height: 16px;
            }
          }
          .booths__main {
            grid-template-columns: 1fr;
            margin: 0;
            padding: 0 16px;
          }

          .fields__files,
          .fields__company-info {
            position: relative;
            width: 100%;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            margin: 0;
            padding: 0 16px;
          }
          .booth {
            padding: 0 16px;
          }
          .next-button {
            padding: 0 16px;
            margin: 0;
            text-align: center;
            button {
              width: 100%;
              height: 40px;
              font-size: 14px;
              line-height: 18px;
              border-radius: 20px;
              font-weight: normal;
            }
          }
        }

      }
    }
  }
  .croppie-container_close {
    position: absolute;
    right: 16px;
    top: 14px;
    width: 24px;
    height: 24px;
    z-index: 1;
    img {
      max-width: 100%;
    }
  }
  .croppie-btns-row {
    padding: 0 16px;
    margin: 0;
    text-align: center;
    button {
      width: 100%;
      height: 40px;
      font-size: 14px;
      line-height: 18px;
      border-radius: 20px;
      font-weight: normal;
    }
  }
  .croppie-container-title {
    font-size: 12px;
    line-height: 16px;
    padding: 0 10px;
  }
  .croppie-container {
    padding: 75px 0 36px 0;
  }
}


