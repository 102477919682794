@import '../abstracts/helpers/functions';
@import '~croppie/croppie.css';

.croppie-container {

  .container--engine {
    // height: rem(300px);
    // width: rem(600px);
    // width: 100%;
    // display: flex;
    // justify-content: center;

    // .cr-boundary {
    //   transform: scaleX(calc(600 / 940)) scaleX(calc(300 / 270));
    // }
  }

  >button {
    color: black !important;
    border-bottom: 5px solid !important;
  }
}

.black-color {
  color: black;
}

.b-bottom {
  border-bottom: 5px solid black;
}
