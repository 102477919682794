.App {

  .invalid ~ .fake-input {
    border-bottom: 1px solid #f44336;
    box-shadow: 0 1px 0 0 #f44336;
  }

  input:focus ~ .fake-input {
    border-bottom: 1px solid #26a69a;
    box-shadow: 0 1px 0 0 #26a69a;
  }

  .fake-input {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #9e9e9e;
    border-radius: 0;
    outline: none;
    height: 3rem;
    width: 100%;
    font-size: 16px;
    margin: 0 0 8px 0;
    padding: 0;
    box-shadow: none;
    box-sizing: content-box;
    transition: box-shadow 0.3s, border 0.3s;
  }
}
.App-content {

  .section {
    display: block;
    width: 100%;
  }
}
