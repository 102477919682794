.react-calendar {
  width: 100%;
  height: 100%;
  padding: $margin-md;


  &__navigation {
    justify-content: center;
    margin-bottom: $margin-md;


    > button {
      flex-grow: 0 !important;
      border: 0;
      cursor: pointer;
    }

    &__prev2-button, &__next2-button {
      display: none;
    }

    &__arrow {
      color: transparent;
      background: url("../../images/icon-arrow.png") no-repeat center;
      border-radius: 50px;
    }

    &__prev-button {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg);
    }

    &__label {
      font-size: $font-size-small;
      font-family: $font-main-bold;
      line-height: 1.14;
      letter-spacing: 0.56px;
      text-align: center;
      color: $color-font;
      border-radius: 50px;
    }
  }

  &__month-view__weekdays {
    margin-bottom: calc(#{$margin-md} / 2);

    &__weekday {
      text-align: center;
      font-size: $font-size-smallest;
      line-height: 1.2;
      color: $color-light-gray;
      font-family: $font-main-bold;
      text-transform: uppercase;

      abbr {
        text-decoration: none;
      }
    }
  }

  &__month-view__days {
    display: grid !important;
    grid-template-columns: repeat(7, 1fr);

    .activeForExpo {
      cursor: pointer;
      border-top: solid 2px $color-blue;
      border-bottom: solid 2px $color-blue;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: visible !important;

      &.weekStart, &.expoStartDay {
        border-left: solid 2px $color-blue;
        border-bottom-left-radius: 50px;
        border-top-left-radius: 50px;
        margin-left: 5px;
        padding-right: 7px;
        width: calc(100% - 5px) !important;
        overflow: visible !important;
      }

      &.weekEnd, &.expoEndDay {
        border-right: solid 2px $color-blue;
        border-bottom-right-radius: 50px;
        border-top-right-radius: 50px;
        margin-right: 5px;
        padding-left: 7px;
        width: calc(100% - 5px) !important;
        overflow: visible;
      }

      &.weekStart.expoEndDay, &.weekEnd.expoStartDay{
        margin-left: 5px;
        margin-right: 5px;
        padding: 0;
        width: calc(100% - 10px) !important;
      }

      &:hover abbr {
        background-color: $color-blue;
        color: $color-white;
        transform: scale(1.02)
      }

      &.react-calendar__tile--active {
        abbr {
          background-color: $color-blue;
          color: $color-white;
          transform: scale(1.02)
        }
      }
    }

    &__day {
      padding: 0;
      border: 0;
      height: 35px;
      font-size: $font-size-small;
      line-height: 1.2;
      font-family: $font-main-bold;
      color: $color-font;
      width: 100% !important;
      max-width: 100% !important;

      &--neighboringMonth {
        color: $color-light-gray;
      }

      abbr {
        border-radius: 50px;
        width: 33px;
        height: 33px;
        line-height: 33px;
      }
    }
  }
}
