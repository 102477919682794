@import "../abstracts/helpers/functions";


.map-container {
  .justContent {
    justify-content: space-between;
  }

  .size {
    box-shadow: 0 0 20px rgba(100, 100, 100, .9);
    -ms-transform: scale(1.01);
    // /* IE 9 */
    -webkit-transform: scale(1.01);
    // /* Safari 3-8 */
    transform: scale(1.01);
  }

  .imgWrapperBig {
    position: absolute;
    width: 374px;
    height: 258px;
    bottom: 1px;
    left: 179px;

    > img {
      width: 100%;
      height: 100%;
    }
  }
}
