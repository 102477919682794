.drop-zone {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 185px;
  outline: none;
  flex-grow: 1;
  border-radius: 12px;
  border: 2px solid rgba(33, 150, 243, 0.3);

  &-cont {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto;
    margin: 12px 6px 0 16px;
    padding-right: 10px;
    flex-grow: 1;
    overflow-y: auto;

    &__files {
      display: flex;
      flex-direction: column;

      .files__assigned-files {

        &_header {
          display: grid;
          grid-template-columns: 1fr auto auto;

          .assigned_header_name {
            font-size: 14px;
            color: #000000;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .assigned_header_upload {
            width: 17px;
            height: 17px;
            cursor: pointer;
            margin: 0 14px;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .assigned_header_count {
            font-size: 12px;
            color: #C8C7C7;
            cursor: pointer;

            span:first-child {
              margin-right: 4px;
            }

            span:last-child img {
              transform: rotate(-180deg);
              transition: .2s;
            }
          }
        }

        input[type=file] {
          display: none;
        }

        &.selected {
          order: -1;

          .assigned_header_name {
            color: $color-blue;
          }
        }

        &.hide-files {
          .files__assigned-files_header .assigned_header_count {
            span:last-child img {
              transform: rotate(0);
            }
          }

          .files__assigned-files_box {
            min-height: 0;
            height: 0;
          }
        }
      }

      .files__other-files, .files__assigned-files {
        border-bottom: 1px solid #C8C7C7;
        padding: 14px 0 12px;

        &_header {
          font-size: 14px;
          line-height: 1.21;
          color: #000000;
          margin-bottom: 14px;
        }

        &_box {
          min-height: 10px;
          display: flex;
          flex-direction: column;
          align-items: center;
          overflow: hidden;
        }

        .file {
          width: 100%;
          display: grid;
          grid-template-columns: auto auto minmax(100px, 1fr) auto;
          user-select: none;
          margin-bottom: 8px;

          &_swipe {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 16px;
          }

          &_icon {
            width: 28px;
            height: 28px;
            margin-right: 8px;
          }

          &_name {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-right: 8px;

            p {
              font-size: 8px;
              font-family: $font-main-bold;
              color: #000000;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            p.success {
              font-family: $font-main-regular;
              margin: 4px 0 0;
            }

            p.hide {
              display: none;
            }

            .green {
              width: 100%;
              margin-top: 4px;
              height: 2px;
              background-color: #27AE60;
              border-radius: 15px;
            }
          }

          &_delete-icon {
            cursor: pointer !important;
          }
        }
      }
    }

    .drop-zone-img {
      width: 100%;
      height: 100%;
      max-height: 180px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      overflow-y: auto;

      .drop-empty {
        cursor: pointer;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        * {
          pointer-events: none;
        }

        h5 {
          margin-top: 7px;
          color: black;
          font-size: 9px;
          line-height: 11px;
        }

        p {
          margin: 0;
          color: #BFBFBF;
          font-size: 9px;
          line-height: 11px;
        }
      }

      .choose-file-label {
        margin-top: 8px;
        background-color: #2196F3;
        color: white;
        font-size: 9px;
        padding: 4px 16px;
        border-radius: 5px;
        text-transform: capitalize;
        cursor: pointer;
      }

      .green {
        width: 100%;
        margin-top: 4px;
        height: 2px;
        background-color: #27AE60;
        border-radius: 15px;
      }

      .blue {
        width: 100%;
        margin-top: 8px;
        margin-left: 8px;
        height: 4px;
        background-color: #2196F3;
        border-radius: 15px;
      }

      .red {
        width: 100%;
        margin-top: 8px;
        margin-left: 8px;
        height: 4px;
        background-color: #FE4C4C;
        border-radius: 15px;
      }
    }

  }

  &__drag {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: $margin-md 0;
    margin: 0 $margin-md;
    border-top: solid 1px #68b7f4;

    h5 {
      font-size: 8px;
      line-height: 1.13;
      text-align: center;
      color: #8e8e8e;

      span {
        font-family: $font-main-bold;
        color: #000000;

      }
    }

    label {
      padding: 4px 23px;
      border-radius: 5px;
      background-color: #2196f3;
      margin-top: $margin-sm;
      color: $color-white;
      font-size: 8px;
      line-height: 1.25;
      cursor: pointer;
    }
  }
}

.redPL {
  color: red !important;
}

input.redPL::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: red;
  opacity: 1; /* Firefox */
}

input.redPL:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: red;
}

input.redPL::-ms-input-placeholder { /* Microsoft Edge */
  color: red;
}

.inside-img {
  width: 100%;
}

.drop-zone-icon-block {
  width: 30px;
  height: 30px;
  display: flex;
  flex-direction: column;

  > img {
    width: 100%;
    height: 100%;
  }
}

.success-block {
  width: 100%;
  height: 100%;
  padding-left: 8px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  flex-wrap: nowrap;

  > img {
    width: 100%;
    height: 100%;
  }

  > p {
    max-width: 300px;
    margin: 2px 0 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-weight: bold;
    font-size: 9px;
  }
}

.success {
  margin-left: 8px;
  color: #27AE60;
}

.success-close {
  display: flex;
  flex-direction: column;
  width: 9px;
  height: 9px;
  cursor: pointer;
  position: absolute;
  right: 4px;
  top: 4px;

  img {
    pointer-events: none;
    width: 100%;
  }
}

.drop-img-add {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  position: relative;
}
