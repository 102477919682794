@font-face {
  font-family: 'icomoon', sans-serif;
  src:  url('fonts/icomoon.eot?v453mp');
  src:  url('fonts/icomoon.eot?v453mp#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?v453mp') format('truetype'),
    url('fonts/icomoon.woff?v453mp') format('woff'),
    url('fonts/icomoon.svg?v453mp#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon', sans-serif !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ic_branding:before {
  content: "\e907";
}
.camera:before {
  content: "\e905";
}
.cheveron-left:before {
  content: "\e902";
}
.cheveron-right:before {
  content: "\e903";
}
.close:before {
  content: "\e906";
}
.information-outline:before {
  content: "\e904";
}
.ic_logo:before {
  content: "\e908";
}
.ic_runner:before {
  content: "\e909";
}
.ic_monitor:before {
  content: "\e90a";
}
.ic_add:before {
  content: "\e90c";
  color: #ccc;
}
.add:before {
  content: "\e90b";
}
.arrow_right:before {
  content: "\e900";
}
.arrow_left:before {
  content: "\e901";
}
