@import "../abstracts/helpers/functions";
.floor-plan-wrap {
  display: grid;
  grid-template-columns: 9fr 3fr;
  grid-template-areas: "left right";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  //overflow: hidden;
  .floor-plan-wrap-left {
    grid-area: left;
    min-width: 0;
    position: relative;
  }
  .floor-plan-wrap-right {
    grid-area: right;
    min-width: 0;
  }
  .floor-plan {
    position: absolute;
    top: 0;
    left: 0;
    .pad-left {
      padding-left: 16px !important;
    }
    .container-advertising {
      width: 100%;
      min-height: 100%;
      display: flex;
      flex-direction: column;
      border-radius: rem(10px);
      margin: 0;
      .b-bottom-active {
        box-shadow:0 5px 0 0 orange;
        border: none;
      }
      .black-color {
        color: black !important;
      }
      .b-bottom {
        border-bottom: 5px solid black;
      }
      .orange-color {
        color: orange;
      }
      .cont {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        &-p {
          display: flex;
          justify-content: center;
          padding: 4px 32px;
          > p {
            text-align: center;
            width: 80%;
            color: black;
          }
        }
        &-lab {
          margin-top: 16px !important;
          width: 100%;
          > div {
            display: flex;
            justify-content: center !important;
            > label {
              margin: 0 !important;
              color: black;
              // > input {
              //   border-bottom: 5px solid black !important;
              // }
            }
          }
        }
      }
      .content {
        display: flex;
        flex: 1 0 auto;
        width: 100%;
        background: #11141d;
        background-size: cover;
        background-repeat: no-repeat;
        padding: 0;
        .content__row {
          display: flex;
          width: 100%;
          padding-left: em(45px);
        }
        .content__image {
          img {
            display: block;
            right: 0;
            top: 0;
          }
        }
        .content__col {
          max-width: rem(360px);
          padding: em(150px) 0;
          z-index: 5;
        }
        h1 {
          font-size: em(100px);
          letter-spacing: 5px;
        }
      }
      .footer {
        background: #11141d;
      }
    }
    .halls__zones {
      padding: 0;
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      .halls-zones-inner {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding-bottom: 20px;
      }
      .carousel .control-next {
        right: 10px;
      }
    }
    .floor-plan__main_halls .halls__zones .carousel .control-prev {
      left: 10px;
    }
    .floor-nav-bar {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(14%, 1fr));
      grid-row-gap: 16px;
      grid-column-gap: 8px;
      margin-bottom: 20px;
      &.show-product-branding {
        & ~ .halls__zones .map-zone .map-zone-branding {
          display: block;
        }
        .floor-nav-bar-inner:nth-child(1) {
          font-weight: bold;
          background: #F9F9F9;
          box-shadow: 0 3px 6px #0000003D;
          .floor-nav-bar-i-c {
            transform: scale(1.1);
          }
        }
      }
      &.show-product-runner {
        & ~ .halls__zones .map-zone .map-zone-runner {
          display: block;
        }
        .floor-nav-bar-inner:nth-child(2) {
          font-weight: bold;
          background: #F9F9F9;
          box-shadow: 0 3px 6px #0000003D;
          .floor-nav-bar-i-c {
            transform: scale(1.1);
          }
        }
      }
      &.show-product-logo {
        & ~ .halls__zones .map-zone .map-zone-logo {
          display: block;
        }
        .floor-nav-bar-inner:nth-child(3) {
          font-weight: bold;
          background: #F9F9F9;
          box-shadow: 0 3px 6px #0000003D;
          .floor-nav-bar-i-c {
            transform: scale(1.1);
          }
        }
      }
      &.show-product-monitor {
        & ~ .halls__zones .map-zone .map-zone-monitor {
          display: flex;
        }
        .floor-nav-bar-inner:nth-child(4) {
          font-weight: bold;
          background: #F9F9F9;
          box-shadow: 0 3px 6px #0000003D;
          .floor-nav-bar-i-c {
            transform: scale(1.1);
          }
        }
      }
    }
    .map-zone-branding {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      width: 100%;
      height: 100%;
      max-height: 318px;
    }
    .map-zone-b-top {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 9px;
      position: absolute;
      bottom: 100%;
      left: 0;
      .map-zone-b-inner {
        height: 24px;
        margin-right: 2px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
    .map-zone-b-bottom {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 9px;
      position: absolute;
      top: 100%;
      left: 0;
      .map-zone-b-inner {
        height: 24px;
        margin-right: 2px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
    .map-zone-b-right {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      top: 0;
      left: 100%;
      .map-zone-b-right-inner {
        display: flex;
        flex-direction: column;
        height: 100%;
        margin-bottom: 50px;
        &:last-child {
          margin-bottom: 0;
          align-items: flex-end;
        }
      }
      .map-zone-b-inner {
        width: 24px;
        margin-bottom: 2px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .map-zone-b-left {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      top: 0;
      right: 100%;
      .map-zone-b-right-inner {
        display: flex;
        flex-direction: column;
        height: 100%;
        margin-bottom: 50px;
        &:last-child {
          margin-bottom: 0;
          align-items: flex-end;
        }
      }
      .map-zone-b-inner {
        width: 24px;
        margin-bottom: 2px;
          &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .map-zone-b-inner {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #E5E5E5;
      font-size: 13px;
      line-height: 16px;
      color: #9a9a9a;
      background-size: cover;
      background-repeat: no-repeat;
      cursor: pointer;
      overflow: hidden;

      img {
        display: block;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }

      &.reserved, &.disabled {
        position: relative;
        pointer-events: none;
        &:before {
          position: absolute;
          content: "";
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
          background-color: #bebebe;
          transform: rotate(16deg);
        }
        &:after {
          position: absolute;
          content: "";
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
          background-color: #bebebe;
          transform: rotate(-16deg);
        }
      }
    }
    .map-zone-b-top,
    .map-zone-b-bottom {
      .map-zone-b-inner.reserved {
        &:before, &:after {
          width: 100%;
          height: 1px;
        }
      }
    }
    .map-zone-b-left,
    .map-zone-b-right {
      .map-zone-b-inner.reserved {
        &:before, &:after {
          width: 1px;
          height: 100%;
        }
      }
    }
    .map-zone-runner {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      span {
        pointer-events: none;
        white-space: nowrap;
        font-size: 10px;
        line-height: 13px;
        color: #9a9a9a;
      }

      div {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 50%;
        cursor: pointer;


        &.disabled, &.reserved {
          pointer-events: none;
        }

        &.active {
          z-index: 1;
        }
      }
    }
    .map-zone-r-top {
      width: calc(100% + 26px);
      height: 13px;
      background-color: #c5c5c5;
      position: absolute;
      top: -13px;
      left: -13px;
      span {
        position: absolute;
        right: 0;
        top: 0;
        left: 0;
        margin: 0 auto;
      }
    }
    .map-zone-r-bottom {
      width: calc(100% + 26px);
      height: 13px;
      background-color: #c5c5c5;
      position: absolute;
      bottom: -13px;
      left: -13px;
      span {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        margin: 0 auto;
      }
    }
    .map-zone-r-right {
      width: 13px;
      height: calc(100% + 26px);
      background-color: #c5c5c5;
      position: absolute;
      right: -13px;
      top: -13px;
      span {
        position: absolute;
        right: -40px;
        top: 42%;
        transform-origin: 0 0;
        transform: rotate(90deg);
      }
    }
    .map-zone-r-left {
      width: 13px;
      height: calc(100% + 26px);
      background-color: #c5c5c5;
      position: absolute;
      left: -13px;
      top: -13px;
      span {
        position: absolute;
        left: 12px;
        top: 42%;
        transform-origin: 0 0;
        transform: rotate(90deg);
      }
    }
    .map-zone-logo {
      display: none;
      position: absolute;
      top: -24px;
      left: 0;
      width: 100%;
      height: 100%;
      .map-zone-l-inner {
        width: 90px;
        height: 24px;
        margin: 0 auto;
        background-color: #e5e5e5;
        padding: 0 2px 0 4px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        cursor: pointer;

        &.disabled, &.reserved {
          pointer-events: none;
        }

        span {
          pointer-events: none;
          font-size: 14px;
          line-height: 19px;
          color: #9a9a9a;
        }
      }
    }
    .floor-nav-bar-inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 16px 10px 26px 10px;
      transition: 240ms;
      border-radius: 8px;
      cursor: pointer;

      &.disabled {
        user-select: none;
        pointer-events: none;
      }
      .floor-nav-bar-i-c {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        transition: transform 240ms;
      }
      i {
        position: relative;
        color: #363F5A;
        font-size: 36px;
        padding: 10px;
        margin-bottom: 6px;
        span {
          position: absolute;
          content: "";
          font-size: 10px;
          color: #ffffff;
          background-color: #74A457;
          padding: 2px;
          border-radius: 50%;
          min-width: 16px;
          min-height: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          top: 0;
          right: 0;
        }
      }
      &:hover {
        font-weight: bold;
        background: #ffffff;
        box-shadow: 0 3px 6px #0000003D;
      }
      &:not(.active):hover {
        .floor-nav-bar-i-c {
          transform: scale(1.1);
        }
      }
    }
    .nav-bar-i-title {
      font-size: 16px;
      line-height: 20px;
      color: #363F5A;
      text-align: center;
      word-break: break-all;
      text-transform: capitalize;
    }
    .floor-plan__title {
      margin: 0;
      .reminder-block {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        margin-top: 8px;
        p {
          display: inline-block;
          vertical-align: top;
          font-weight: bold;
          margin-right: 8px;
          margin-top: 0;
        }
        .remainder-count {
          font-size: 14px;
          line-height: 24px;
          padding: 0 16px;
          border-radius: 8px;
          background-color: #545F7E;
          color: #ffffff;
          font-weight: bold;
        }
      }
      h2 {
        font-size: 40px;
        line-height: 48px;
        color: #363F5A;
      }
    }
    .floor-plan-content {
      width: 100%;
      height: 100%;
      position: relative;
      .floor-plan__main {
        position: absolute;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
        display: grid;
        grid-template-rows: auto 11fr;
        grid-row-gap: 20px;
        .floor-plan__main_halls {
          position: relative;
          .floor-plan-main-halls-inner {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: grid;
            grid-template-rows: auto auto 10fr;
            //overflow-x: hidden;
            //overflow-y: auto;
          }
        }
      }
    }
    .halls__zones .map-zone .map-zone_main {
      padding: 34px 0;
      max-width: 410px;
      img {
        max-width: 80%;
        max-height: 80%;
        object-fit: contain;
      }
    }
    .map-zone__title {
      line-height: 20px !important;
      margin-bottom: 64px;
    }
    .map-zone-monitor {
      position: absolute;
      top: -29px;
      left: 0;
      right: 0;
      margin: auto;
      display: none;
      justify-content: space-between;
      width: 100%;
      max-width: 352px;
      padding-bottom: 27px;
      div {
        background-image: url("../../images/monitor.svg");
        background-repeat: no-repeat;
        opacity: .8;
        width: 87px;
        height: 29px;
        cursor: pointer;

        &.disabled, &.reserved {
          pointer-events: none;
        }

        &:hover {
          opacity: 1;
        }

        &.reserved {
          opacity: .2;
          cursor: auto;
        }
      }

      &.revers {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 16px;
        margin-top: 69px;

        .map-zone-monitor {
          width: 29px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 52px;
          padding: 0;
          div {
            background-image: url("../../images/monitor-rotated.svg");
            width: 29px;
            height: 87px;
            margin: 0;
          }
        }
      }

      &.rotated {
        transform: rotate(180deg);
      }

      &.rotated {
        top: auto;
        bottom: -29px;
      }
    }
  }

  .advertising__submit {
    box-shadow: 0 3px 6px #01153B29;
    height: 100%;
    padding: 68px 0;
    .advertising__form {
      display: grid;
      grid-template-rows: auto 10fr auto;
      position: relative;
      width: 100%;
      height: 100%;
      .form__header {
        padding: 0 48px;
        margin: 0 0 16px;
        h2 {
          font-size: 24px;
          line-height: 1.2;
          font-family: $font-main-bold;
          color: #363F5A;
          margin-bottom: 12px;
          text-align: center;
        }

        p {
          font-size: 14px;
          line-height: 1.4;
          color: #F97878;
          text-align: center;
        }
      }
      .fields-files-wrap {
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;
        .fields__files {
          position: absolute;
          top: 0;
          left: 0;
          overflow-x: hidden;
          overflow-y: auto;
          width: 100%;
          max-height: 100%;
          padding: 0 48px;
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(calc(50% - 4px), 1fr));
          grid-gap: 8px;
          .upload-box {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            overflow: hidden;
            background: #F9F9F9;
            cursor: pointer;
            background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='black' stroke-width='1' stroke-dasharray='10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
            img {
              max-width: 32px;
            }
            p {
              font-size: 12px;
              line-height: 18px;
              text-align: center;
              color: #545F7E;
            }
             * {
               display: none;
             }
            &:before {
              content: "\e90b";
              font-family: 'icomoon', sans-serif !important;
              display: block;
              font-size: 22px;
              color: #bebebe;
            }
            &:after {
              content: "\e90c";
              font-size: 44px;
              font-family: 'icomoon', sans-serif !important;
              display: none;
              color: #bebebe;
            }
          }
          .fields__files_created {
            width: 100%;
            height: 43px;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            &:only-child{
              min-height: 130px;
              grid-column: span 2;
              * {
                display: block;
              }
              &:before {
                display: none;
              }
            }
            &:nth-child(1):nth-last-child(2),&:nth-child(2):nth-last-child(1){
              min-height: 130px;
              grid-column: span 2;
              //* {
              //  display: block;
              //}
              &:after {
                display: block;
              }
              &:before {
                display: none;
              }
            }
          }
          .browse-input {
            input {
              display: none;
            }
            padding: 8px 30px;
            border: 1px solid #545F7E;
            border-radius: 20px;
            color: #545F7E;
            font-size: 12px;
            line-height: 16px;
            font-weight: bold;
            cursor: pointer;
          }
          .fields__files_created_img {
            display: block;
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
          .react-thumbnail-generator {
            width: 100%;
            height: 100%;
            img {
              display: block;
              height: 100%;
              width: 100%;
              object-fit: cover;
            }
          }
        }
      }
    }

    .next-button {
      margin: 24px 0 24px auto;
       button {
         border-radius: 25px 0 0 25px;
         background: #363F5A;
         opacity: .6;
         width: 190px;
         height: 50px;
         border: 0;
         font: normal normal 700 16px/25px $font-main-montserrat;
         color: #FFFFFF;
         cursor: pointer;
       }
    }
  }
  .popup-wrap {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9;
    backdrop-filter: blur(10px);
    max-height: 0;
    overflow: hidden;
    transition: background-color 180ms;
    .popup-wrap-inner {
      position: relative;
      width: 100%;
      height: 100%;
      max-width: 1088px;
      max-height: 524px;
      padding: 54px 0;
      z-index: 10;
      background-color: #ffffff;
      transform: translate(0,-40px);
      transition: transform 240ms;
      .close {
        position: absolute;
        color: #bebebe;
        font-family: 'icomoon', sans-serif !important;
        top: 16px;
        right: 16px;
        cursor: pointer;
      }
    }

    &.show {
      max-height: unset;
      background-color: rgba(#000000, 0.6);

      .popup-wrap-inner {
        transform: translate(0, 0);
      }
    }
  }

  .advertising__croppie {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    .croppie-modal {
      display: none;
    }
  }

  .work-area-wrap {
    display: grid;
    grid-template-rows: auto 10fr auto;
    position: relative;
    width: 100%;
    height: 100%;
    grid-gap: 30px;
  }

  .work-area-top {
    display: flex;
    flex-direction: column;
    align-items: center;
    h1 {
      font-size: 29px;
      line-height: 40px;
      color: #363f5a;
    }
    p {
      font-size: 16px;
      line-height: 22px;
      color: #363f5a;
    }
  }
  .work-area-middle {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  .work-area-middle-inner {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 74px;
    overflow-x: hidden;
    overflow-y: auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(18%, 1fr));
    grid-gap: 15px;
    .upload-box {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 62px;
      padding: 20px 0;
      overflow: hidden;
      background: #F9F9F9;
      cursor: pointer;
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='black' stroke-width='1' stroke-dasharray='10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
      img {
        max-width: 32px;
      }
      p {
        font-size: 12px;
        line-height: 18px;
        text-align: center;
        color: #545F7E;
      }
      * {
        display: none;
      }
      &:before {
        content: "\e90b";
        font-family: 'icomoon', sans-serif !important;
        display: block;
        font-size: 22px;
        color: #bebebe;
      }
      &:after {
        content: "\e90c";
        font-size: 44px;
        font-family: 'icomoon', sans-serif !important;
        display: none;
        color: #bebebe;
      }
        &:only-child{
          height: 100%;
          //grid-column: span 2;
          * {
            display: block;
          }
          &:before {
            display: none;
          }
        }
        &:nth-child(1):nth-last-child(2),&:nth-child(2):nth-last-child(1){
          min-height: 130px;
          &:after {
            display: block;
          }
          &:before {
            display: none;
          }
        }
      }
      .browse-input {
        input {
          display: none;
        }
        padding: 8px 30px;
        border: 1px solid #545F7E;
        border-radius: 20px;
        color: #545F7E;
        font-size: 12px;
        line-height: 16px;
        font-weight: bold;
        cursor: pointer;
      }
      .fields__files_created_img {
        display: block;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
      .react-thumbnail-generator {
        width: 100%;
        height: 100%;
        img {
          display: block;
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
    }
    .work-area-middle-i-row {
      width: 100%;
      height: 62px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      &:nth-child(1):nth-last-child(2),&:nth-child(2):nth-last-child(1){
        min-height: 130px;
      }
    }
  //.work-area-middle-i-row {
  //  display: flex;
  //  align-items: center;
  //  justify-content: center;
  //  height: 62px;
  //  img {
  //    display: block;
  //    height: 100%;
  //    width: 100%;
  //    object-fit: cover;
  //  }
  //}
  .work-area-bottom {
    .next-button {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      button {
        border-radius: 25px 0 0 25px;
        background: #363F5A;
        opacity: 0.6;
        width: 190px;
        height: 50px;
        border: 0;
        font: normal normal 700 16px/25px "Montserrat", sans-serif;
        color: #FFFFFF;
        cursor: pointer;
      }
    }
  }
  @media only screen and (max-width: 979px) {
    grid-template-columns: 12fr;
    .floor-plan-wrap-right {
      display: none;
    }
    .floor-plan {
      .floor-plan__title {
        h2 {
          font-size: 18px;
          line-height: 22px;
        }
      }
      .nav-bar-i-title {
        font-size: 12px;
        line-height: 16px;
        word-break: break-word;
      }
      .floor-nav-bar-inner {
        padding: 8px;
        i {
          font-size: 22px;
        }
      }
    }
    .floor-plan__main .floor-plan__main_halls .halls__zones .map-zone  {
      .map-zone__title {
        margin-bottom: 40px !important;
      }
      .map-zone_main {
        max-width: 214px !important;
        padding: 22px 14px;
        img {
          max-width: 100% !important;
        }
      }
    }
    .floor-plan .map-zone-b-left .map-zone-b-right-inner,
    .floor-plan .map-zone-b-right .map-zone-b-right-inner {
      margin-bottom: 32px !important;
      .map-zone-b-inner {
        padding: 12px 0;
      }
    }
    .floor-plan__main .floor-plan__main_halls .halls__zones .carousel .control-prev,
    .floor-plan__main .floor-plan__main_halls .halls__zones .carousel .control-next {
      top: 112% !important;
    }
    .map-zone-b-top,
    .map-zone-b-bottom{
      .map-zone-b-inner.reserved:after {
        transform: rotate(-28deg);
      }
      .map-zone-b-inner.reserved:before {
        transform: rotate(28deg);
      }
    }
    .map-zone-b-right,
    .map-zone-b-left{
      .map-zone-b-inner.reserved:after {
        transform: rotate(-20deg);
      }
      .map-zone-b-inner.reserved:before {
        transform: rotate(20deg);
      }
    }
    .fields__files_created, .work-area-middle-i-row {
      &:only-child{
        min-height: 122px !important;
      }
      &:nth-child(1):nth-last-child(2),&:nth-child(2):nth-last-child(1){
        min-height: 122px !important;
      }
    }
    .popup-wrap {
      .popup-wrap-inner {
        max-width: 100%;
        max-height: 100%;
        padding: 36px 0;
      }
      .work-area-middle-inner {
        padding: 0 16px;
        .work-area-middle-i-row {
          min-height: 40px;
        }
      }
    }
    .work-area-top {
      h1 {
        font-size: 18px;
      }
      p {
        font-size: 12px;
      }
    }
    .work-area-bottom {
      .next-button {
        button {
          height: 40px;
        }
      }
    }
    .floor-plan {
      .floor-plan-content {
        .floor-plan__main {
          .floor-plan__main_halls {
            overflow-y: auto;
            overflow-x: hidden;
            .floor-plan-main-halls-inner {
              position: static;
              top: unset;
              left: unset;
              display: block;
              height: unset;
              grid-template-rows: unset;
              .halls__zones {
                padding: 0 !important;
                //border-left: 10px solid transparent !important;
                //border-right: 10px solid transparent !important;
                border-left: 0 solid transparent !important;
                border-right: 0 solid transparent !important;
                .halls-zones-inner {
                  position: static;
                  top: unset;
                  left: unset;
                  height: unset;
                }
              }
            }
          }
        }
      }
    }
  }
}


