.page-loader {
  display: flex;
  position: fixed;
  top: -50px;
  left: 0;
  width: 100%;
  height: 100%;
  transform: rotate(15deg);
  z-index: 1000;
}

.custLoad {
  // z-index: 99999;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  // background-image: url('../../assets/images/loadingGif.svg');
}

.page-loader .loader-section {
  position: fixed;
  top: -500%;
  width: 101%;
  height: 1000%;
  background: #ffffff;
  transform: translateX(0);
}

.page-loader .loader-section.section-left {
  left: -50%;
}

.page-loader .loader-section.section-right {
  right: -50%;
}

.page-loader.loaded .loader-section.section-left {
  transform: translateX(-100%);
  transition: all 1.5s 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.page-loader.loaded .loader-section.section-right {
  transform: translateX(100%);
  transition: all 1.5s 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.page-loader.loaded .spinner-layer {
  opacity: 0;
}

.page-loader.loaded {
  visibility: hidden;
}

/*  SPINNER  */

.preloader-wrapper {
  display: inline-block;
  position: relative;
  width: 70px;
  height: 70px;

  &.small {
    width: 50px;
    height: 50px;
  }

  &.big {
    width: 85px;
    height: 85px;
  }
}

.spinner-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 1;

  border-radius: 50%;
  border: 10px solid rgba(53, 53, 53, 0.7);
  border-top-color: transparent !important;
  border-right-color: transparent !important;

  transition: opacity 1s ease-out;
  animation: spin-one 2s linear infinite;

  &:before,
  &:after {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 10px solid rgba(53, 53, 53, 0.7);
    border-top-color: transparent !important;
    border-right-color: transparent !important;
  }

  &:before {
    animation: spin-two 1s linear infinite;
  }

  &:after {
    transform: scale(0.6);
  }
}

@keyframes spin-one {
  0% {
    transform: rotateX(0) rotateY(0) rotateZ(0);
  }

  100% {
    transform: rotateX(0) rotateY(0) rotateZ(360deg);
  }
}

@keyframes spin-two {
  0% {
    transform: rotateZ(0);
  }

  100% {
    transform: rotateZ(-360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
