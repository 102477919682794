// Old Version start -----------------------------------------
.container-stand-item {
  margin: 0;
}

.dflex {
  display: flex !important;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
}

.dFlexCol {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  margin-left: 8px;
}

.dFlex_col {
  display: flex;
  flex-direction: column;
  position: relative;
}

.height6975 {
  height: 69.75rem;
}

.padding0 {
  padding: 0px !important;
}

.mtop35px {
  margin-top: 35px;
}

.centerFlex {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.noMLeft {
  margin-left: 0px !important;
}

.posRel {
  position: relative;
}

//Old Version END-----------------------------------------------------------------
