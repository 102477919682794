@keyframes fadeInImg {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.img-loading {
  opacity: 0;
  width: 100%;
  height: auto;
}

.img-loaded {
  opacity: 1;
  //animation: fadeInImg cubic-bezier(0.23, 1, 0.32, 1) 1;
  //position: relative;
  //opacity: 0;
  //animation-fill-mode: forwards;
  //animation-duration: 0.7s;
  //animation-delay: 0.1s;
}
