.conf {
  width: 100%;
  height: 100vh;
  background: url('../../images/confbg.png') no-repeat 50% 50% / auto 120%;
  padding: 80px 0;

  .conf-container {
    background-color: $color-gray;
    height: 100%;
    padding: 18px 0;

    .conf-grid-holder {
      max-width: 1376px;
      height: 100%;
      margin: 0 auto;
      display: grid;
      grid-template-columns: 364px 1fr;
      grid-template-rows: auto 1fr;
      gap: 18px;

      > div {
        background-color: $color-white;
        border-radius: $radius-lg;
        overflow: hidden;
      }
    }

    .conf__calendar.selected {
      border: solid 2px $color-blue;
    }

    .conf__time-zone {
      grid-row-start: 2;
      grid-row-end: 3;
      grid-column-start: 1;
      grid-column-end: 2;
    }

    .conf__user-info {
      grid-row-start: 1;
      grid-row-end: 3;
      grid-column-start: 2;
      grid-column-end: 3;
    }
  }
}

.conf__time-zone {
  padding: $margin-md;
  position: relative;
  border: solid 2px transparent;

  &.selected {
    border: solid 2px $color-blue;
  }

  .scroll-contain {
    height: 100%;
    max-height: 100%;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 2px;
    }

    &::-webkit-scrollbar-track {
    }

    &::-webkit-scrollbar-thumb {
      background-color: $color-light-gray;
    }
  }

  &__range {
    position: relative;
    padding-top: 8px;

    .time-zone__slot {
      position: relative;
      display: grid;
      grid-template-columns: 33px 1fr;
      height: 32px;
      padding: 0 20px 0 0;
      gap: $margin-sm;


      &_time {
        text-align: right;
        font-size: $font-size-smallest;
        line-height: 1.25;
        color: $color-black;
        margin-top: -8px;
      }

      &_line {
        position: relative;
        cursor: pointer;
        border-top: 1px solid $color-light-gray;
      }

      &_reserved, &_reserving {
        position: absolute;
        width: 100%;
        z-index: 1;
        text-align: right;
        padding: 1px 20px 0 61px;

        .slot_reserved-box {
          cursor: pointer;
          border-radius: $radius-sm;
          border: 1px solid $color-dark-gray;
          background-color: $color-white;
          height: 100%;
          text-align: left;
          font-size: $font-size-smallest;
          line-height: 1.25;
          color: $color-dark-gray;
          padding: 3px;
          font-family: Proxima-Bold, sans-serif;
          display: grid;
          grid-template-columns: 1fr auto;
          gap: 25px;

          &_subject {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding-top: 4px;
          }

          &_icon {
            display: flex;
            overflow: hidden;
            align-items: center;
            padding: 4px;
            border: 1px solid transparent;
            align-self: start;

            span {
              display: none;
              margin-right: $margin-sm;
              font-family: Proxima-Regular, sans-serif;
            }

            img {
              vertical-align: middle;
            }

            .disabled {
              display: block;
            }

            .hover {
              display: none;
            }

            .active {
              display: none;
            }
          }

          &:hover {
            .slot_reserved-box_icon {
              grid-template-columns: 1fr auto;
              border: 1px solid $color-blue;
              border-radius: 5px;

              span {
                display: inline;
              }

              .disabled {
                display: none;
              }

              .hover {
                display: block;
              }

              .active {
                display: none;
              }

            }
          }
        }

        &.selected {
          .slot_reserved-box {
            background-color: $color-blue;
            border-color: $color-blue;
            color: $color-white;

            .disabled {
              display: none;
            }

            .hover {
              display: none;
            }

            .active {
              display: block;
            }

            &:hover {
              .slot_reserved-box_icon {
                border-color: $color-white;
              }

              .hover {
                display: none;
              }

              .active {
                display: block;
              }
            }
          }
        }

      }

      &_reserving {
        .slot_reserving-box {
          background-color: $color-blue;
          border-color: $color-blue;
          color: $color-white;

          .slot_reserving-box_icon {

            span {
              display: none;
            }

            .active {
              display: block !important;
            }
          }

          &:hover .slot_reserving-box_icon {
            display: flex;
            border-color: $color-white !important;

            span {
              display: block;
            }
          }
        }
      }

      &__options-popup {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 0 0 0 $margin-xsm;
        border-radius: 5px;
        border: solid 1px $color-blue;
        background-color: $color-white;
        z-index: 2;
        display: flex;
        justify-content: space-between;
        align-items: start;
        font-size: $font-size-smallest;

        .options-popup_start {
          display: flex;
          align-items: center;
          margin-top: 3px;

          &-name {

          }

          &-value {
            margin-left: $margin-xsm;
            padding: 3px $margin-xsm;
            border-radius: 5px;
            border: solid 1px $color-light-gray;
          }
        }

        .options-popup_duration {
          display: flex;
          align-items: start;
          height: 22px;
          margin-top: 3px;

          &-name {
            align-self: center;
          }

          &-value {
            margin-left: $margin-xsm;
            border-radius: 5px;
            padding: 3px $margin-xsm;
            border: solid 1px $color-light-gray;
            display: flex;
            flex-direction: column;
            background-color: $color-white;

          }

          .selected-duration-value {
            color: $color-blue;
            cursor: pointer;


            img {
              margin-left: $margin-xsm;
              vertical-align: middle;
              transition: .1s;
            }

            &.opened {
              border-bottom: 1px solid $color-light-gray;

              img {
                transform: rotate(-180deg);
              }
            }
          }

          .selected-duration-item {
            cursor: pointer;
            font-size: $font-size-small;
            line-height: 1.21;
            color: #757575;
            padding: 2px 0;

            &:hover {
              color: $color-blue;
            }
          }
        }

        .options-popup_confirm {
          font-size: $font-size-smallest;
          font-family: $font-main-bold;
          letter-spacing: normal;
          color: $color-white;
          background-color: $color-blue;
          height: 100%;
          padding: 0 6px;
          display: flex;
          align-items: center;
          cursor: pointer;
        }
      }
    }

    .time-zone__slot:nth-child(49) {
      height: 1px;
    }

    &_vertical-line {
      position: absolute;
      top: 8px;
      left: 60px;
      height: calc(100% - 8px);
      border-right: 1px solid $color-light-gray;
      pointer-events: none;
    }
  }

  &_disabled {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% + 2px);
    background-color: rgba(255, 255, 255, .75);
    z-index: 1;
  }

  &.disabled {

    .conf__time-zone_disabled {
      display: block;
    }
  }
}

.conf__user-info {
  border-radius: 20px;
  background-color: $color-white;
  border: solid 2px transparent;

  &.selected {
    border: solid 2px $color-blue;
  }

  .conference-info {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 20% 40% 40%;
    height: calc(100% - 2 * #{$margin-md});
    grid-gap: $margin-md;
    padding: 32px;

    &_subject, &_description, &_speakers {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 22px auto;

      p {
        margin: 0 0 $margin-sm $margin-md;
        font-size: $font-size-small;
        line-height: 1.21;
        color: $color-dark-gray;
      }

      &_value {
        padding: $margin-md;
        border-radius: 12px;
        border: solid 2px rgba(33, 150, 243, .25);
        font-size: 14px;
        font-family: $font-main-bold;
        line-height: 1.21;
        color: $color-black;
        overflow-y: auto;
        height: 100%;
      }
    }

    .speakers-box {

      display: flex;
      justify-content: space-around;
      gap: $margin-md;

      &__speaker {
        max-width: 126px;
        display: flex;
        flex-direction: column;
        align-items: center;

        &_avatar {
          width: 100%;
          padding-top: 100%;
          background-repeat: no-repeat;
          background-size: cover;
          border-radius: $radius-half;
          border: solid 2px $color-blue;
        }

        p {
          margin: 0;
          padding: 0;
          font-size: $font-size-smallest;
          color: $color-black;
        }

        &_name {
          font-family: $font-main-bold;
          margin-top: $margin-sm !important;
        }

        &_position {
          margin-top: $margin-xsm !important;
          font-family: $font-main-regular;
          text-align: center;
        }
      }
    }
  }
}

.conf-empty-page {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    font-size: 24px;
    font-family: $font-main-bold;
    line-height: 1.21;
    text-align: center;
    color: $color-blue;
    margin-top: $margin-md;
  }
}

.create-conf {
  display: grid;
  grid-template-columns: 1fr 272px;
  grid-template-rows: 1fr auto;
  padding: 32px;
  height: 100%;
  grid-gap: 0 $margin-md;
  position: relative;

  &__conf-info {
    display: flex;
    flex-direction: column;

    .conf-info_subject, .conf-info_description {
      margin-bottom: $margin-md;

      p {
        font-size: $font-size-small;
        line-height: 1.21;
        color: $color-dark-gray;
        margin-bottom: $margin-sm;
        margin-left: 16px;
      }

      input, .short {
        padding: 16px;
        border-radius: 12px;
        border: solid 2px rgba(33, 150, 243, .25);
        font-size: $font-size-small;
        line-height: 1.21;
        color: $color-black;
        width: 100%;
        box-sizing: border-box;

        &::placeholder {
          color: $color-light-gray;
        }
      }

      .short {
        height: 100%;
        width: 100%;
        overflow-y: auto;
      }
    }

    .conf-info_description {
      flex-grow: 1;
      display: flex;
      flex-direction: column;

      .short {
        flex-grow: 1;
      }
    }

  }

  &__speakers-section {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 3;

    display: grid;
    grid-template-columns: 1fr 156px;
    grid-template-rows: 25px 192px;
    grid-gap: 0 $margin-md;

    &.speakers-count-limited {
      grid-template-columns: 1fr;

      .empty-div {
        display: none;
      }
    }

    .speakers_title {

      p {
        font-size: $font-size-small;
        line-height: 1.21;
        color: $color-dark-gray;
        margin: 0 0 $margin-sm $margin-md;
      }
    }

    .speakers {
      padding: 18px 10px;
      width: 100%;
      border-radius: 12px;
      border: solid 2px rgba(33, 150, 243, .25);
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      min-height: 190px;

      &__speaker-form {
        display: grid;
        grid-template-columns: 126px 1fr;
        grid-gap: 60px;
        height: 100%;

        .speaker-form_avatar {
          align-self: center;
          width: 126px;
          height: 126px;
          border: solid 2px $color-blue;
          border-radius: $radius-half;
          overflow: hidden;
          position: relative;

          &_img {
            width: 100%;
            padding-top: 100%;
            background-repeat: no-repeat;
            background-size: cover;
            cursor: pointer;
          }

          input {
            display: none;
          }

          .custLoad {
            img {
              width: 40px;
            }
          }
          label {
            border-radius: $radius-half;
          }
        }

        .speaker-form_data {
          align-self: center;
          display: flex;
          flex-direction: column;
          justify-content: center;

          input {
            border: 0;
            border-bottom: solid 1px $color-light-gray;
            padding: $margin-xsm;
            font-size: $font-size-smallest;
            line-height: 1.25;
            color: $color-black;

            &:focus {
              border-bottom: solid 1px $color-blue;
            }

            &::placeholder {
              color: $color-light-gray;
            }

            &.redPL {
              &::placeholder {
                color: red !important;
              }
            }
          }
        }
      }

      .carousel-root {
        align-self: stretch;
        width: 100%;

        .carousel, .slider-wrapper, .slider {
          height: 100%;
        }

        .carousel {
          padding: 0 40px;
          overflow: visible;


          .slider-wrapper {
            position: relative;

            .slider {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            }
          }

          .control-arrow {
            opacity: 1;
            width: 30px;
            height: 30px;
            display: block;
            border-radius: $radius-half;
            background: url("../../images/arrow-gray.svg") no-repeat center center;
            background-size: 11px 7px;
            cursor: pointer;
            top: 50%;
            padding: 0;
            margin: 0;
            transform: rotate(90deg) translate(-50%, 0);

            &:before {
              display: none;
            }
          }

          .control-next {
            transform: rotate(-90deg) translate(50%, 0);
            left: auto;
          }

          .control-dots {
            position: absolute;
            bottom: -15px;
            left: 0;
            margin: 0;
            padding: 0;
            z-index: 1000;

            .dot {
              width: 6px;
              height: 6px;
              background: #C8C7C7;
              opacity: 1;
              box-shadow: none;
              border: 0;
            }

            .selected {
              background: #2196F3;
            }
          }
        }
      }

      .closeButton {
        position: absolute;
        top: 12px;
        right: 12px;
        width: 14px;
        height: 14px;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .add-speaker-box {
      border: solid 2px rgba(33, 150, 243, .25);
      border-radius: 12px;
      padding: 18px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &_profile-icon {
        border: 1px solid $color-light-gray;
        border-radius: $radius-half;
        margin-bottom: $margin-md;
        width: 92px;
        height: 92px;
        img {
          width: 100%;
          height: 100%;
        }
      }

    }
  }

  &__files {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 3;

    display: flex;
    flex-direction: column;

    .files_title {
      display: flex;
      position: relative;
      flex-grow: 1;

      &_value {
        font-size: $font-size-small;
        line-height: 1.21;
        color: $color-dark-gray;
        margin: 0 0 $margin-sm $margin-md;

      }

      .icon-information-outline {
        margin-left: $margin-sm;
        cursor: pointer;
        color: $color-dark-gray;

        &:hover + .files_title_valid {
          display: block;
        }
      }

      &_valid {
        display: none;
        position: absolute;
        top: 0;
        left: 80px;
        background-color: $color-white;
        border-radius: $radius-md;
        padding: 12px;
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
        font-size: 10px;
        line-height: 1.2;
        color: $color-dark-gray;
      }
    }

    .submit_button {
      width: 100%;
      height: 41px;
      border-radius: 5px;
      background-color: #c8c7c7;
      font-size: 14px;
      font-family: $font-main-bold;
      line-height: 1.21;
      color: #ffffff;
      border: 0;
      margin-top: $margin-sm;
      cursor: pointer;

      &.valid {
        background-color: $color-blue;
      }
    }
  }

  .custLoad {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.7);
  }

}

.submitted-popup {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, .4);
  z-index: 2000;

  &__box {
    width: 596px;
    max-width: 596px;
    padding: 32px;
    border-radius: 20px;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: relative;

    img {
      margin-bottom: 18px;
    }

    &_title {
      font: normal normal 14px/17px $font-main-bold;
      color: #27AE60;
      text-transform: uppercase;
      margin-bottom: 24px;
    }

    &_description {
      font: normal normal normal 14px/17px $font-main-regular;
      color: #757575;
    }

    button {
      border: 0;
      background: #27AE60;
      border-radius: 5px;
      text-align: center;
      font: normal normal normal 14px/17px;
      color: #FFFFFF;
      padding: 12px;
      width: 112px;
      cursor: pointer;
      margin-top: 32px;
    }

    .close-popup {
      position: absolute;
      right: 12px;
      top: 12px;
      cursor: pointer;
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        vertical-align: middle;
        width: 13px;
        height: 13px;
        margin: 0;
      }
    }
  }
}
.carousel.carousel-slider {
  overflow: visible !important;
}
.slider-wrapper {
  overflow: visible !important;
}
