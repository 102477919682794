.page-advertising {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;

  border-radius: rem(10px);

  margin: 0;

  .content {
    display: flex;
    flex: 1 0 auto;
    width: 100%;

    background: #11141d;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 0;

    .content__row {
      display: flex;
      width: 100%;
      padding-left: em(45px);
    }

    .content__image {
      img {
        display: block;
        right: 0;
        top: 0;
      }
    }

    .content__col {
      max-width: rem(360px);
      padding: em(150px) 0;
      z-index: 5;
    }

    h1 {
      font-size: em(100px);
      letter-spacing: 5px;
    }
  }

  .footer {
    background: #11141d;
  }
}
