$browser-context: 16 !default; // Default

/// Base path for assets (fonts, images...),
/// should not include trailing slash
/// @access public
/// @type String
$asset-base-path: '../' !default;

$primary-color: #0000ff;

// Manager Colors
$color-white: #fff; //
$color-blue: #2196f3; //
$color-font: #3d3d3d; //
$color-gray: #f9f9fb; //
$color-light-gray: #c8c7c7; //
$color-dark-gray: #757575; //
$color-black: #000; //

// Content Shadow


// Manager Font Families
$font-main-regular: Proxima-Regular, sans-serif;
$font-main-semi-bold: Proxima-SemiBold, sans-serif;
$font-main-bold: Proxima-Bold, sans-serif;
$font-main-extra-bold: Proxima-Extra-Bold, sans-serif;
$font-main-extra-bold: Proxima-Extra-Bold, sans-serif;
$font-main-montserrat: 'Montserrat', sans-serif;

// Home Font Sizes
$font-size-default: 16px;
$font-size-small: 14px;
$font-size-smallest: 12px;

// default margins and padding
$margin-xsm: 4px;
$margin-sm: 8px;
$margin-md: 18px;

// default border-radius dimensions
$radius-sm: 4px;
$radius-md: 8px;
$radius-lg: 20px; //
$radius-half: 50%;

// media query sizes
$media-width-lg: 1199.98px;
$media-width-md: 991.98px;
$media-width-sm: 767.98px;
$media-width-esm: 575.98px;

