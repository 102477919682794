.loader_rounded {
  width: 66px;
  height: 66px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;

  span {
    font-size: 10px;
    line-height: 1.3;
    font-family: $font-main-bold;
    color: #545F7E;
  }
}
.circular-chart {
  display: block;
  margin: auto;
  height: calc(100% - 16px);
  width: calc(100% - 16px);
  position: absolute;
  top: 8px;
  left: 8px;
  z-index: 2;
}

.circle {
  stroke: #a0d282;
  fill: none;
  stroke-width: 1;
  stroke-linecap: round;
  animation: progress 1s ease-out forwards;
}

.circle__percent {
  font-size: 10px;
  fill: $color-blue;
}

@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}
